/* tslint:disable */
/* eslint-disable */
/**
 * VascoOmsLte.WebApi API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivateOrder
 */
export interface ActivateOrder {
    /**
     * 
     * @type {string}
     * @memberof ActivateOrder
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof ActivateOrder
     */
    'activationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ActivateOrder
     */
    'msisdn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivateOrder
     */
    'productOrderReferenceNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface AddNewCard
 */
export interface AddNewCard {
    /**
     * 
     * @type {string}
     * @memberof AddNewCard
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof AddNewCard
     */
    'checkoutId': string;
}
/**
 * 
 * @export
 * @interface AddressessDto
 */
export interface AddressessDto {
    /**
     * 
     * @type {string}
     * @memberof AddressessDto
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof AddressessDto
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressessDto
     */
    'line3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressessDto
     */
    'suburb': string;
    /**
     * 
     * @type {string}
     * @memberof AddressessDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AddressessDto
     */
    'province'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressessDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof AddressessDto
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof AddressessDto
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof AddressessDto
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressessDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface AddressessViewModel
 */
export interface AddressessViewModel {
    /**
     * 
     * @type {string}
     * @memberof AddressessViewModel
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof AddressessViewModel
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressessViewModel
     */
    'line3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressessViewModel
     */
    'suburb': string;
    /**
     * 
     * @type {string}
     * @memberof AddressessViewModel
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AddressessViewModel
     */
    'province'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressessViewModel
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof AddressessViewModel
     */
    'postalCode': string;
}
/**
 * 
 * @export
 * @interface ApiAccountConfirmEmailPostRequest
 */
export interface ApiAccountConfirmEmailPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiAccountConfirmEmailPostRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiAccountConfirmEmailPostRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface ApiAccountLoginPostRequest
 */
export interface ApiAccountLoginPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiAccountLoginPostRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ApiAccountLoginPostRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ApiAccountRegisterPostRequest
 */
export interface ApiAccountRegisterPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiAccountRegisterPostRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ApiAccountRegisterPostRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ApiBillingCycleGetRequest
 */
export interface ApiBillingCycleGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiBillingCycleGetRequest
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof ApiBillingCycleGetRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiBillingCycleGetRequest
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiBillingCycleGetRequest
     */
    'ordersId': string;
}
/**
 * 
 * @export
 * @interface ApiBillingCycleIdGetRequest
 */
export interface ApiBillingCycleIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiBillingCycleIdGetRequest
     */
    'ordersId': string;
    /**
     * 
     * @type {Array<OrderCostsUpdateViewModel>}
     * @memberof ApiBillingCycleIdGetRequest
     */
    'orderCosts'?: Array<OrderCostsUpdateViewModel> | null;
    /**
     * 
     * @type {string}
     * @memberof ApiBillingCycleIdGetRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ApiCustomerPaymentMethodGetRequest
 */
export interface ApiCustomerPaymentMethodGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiCustomerPaymentMethodGetRequest
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface ApiCustomersAdminIdGetRequest
 */
export interface ApiCustomersAdminIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersAdminIdGetRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersAdminIdGetRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersAdminIdGetRequest
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersAdminIdGetRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersAdminIdGetRequest
     */
    'additionalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersAdminIdGetRequest
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersAdminIdGetRequest
     */
    'gender': string;
    /**
     * 
     * @type {UpdateCustomersAdminCommandAddress}
     * @memberof ApiCustomersAdminIdGetRequest
     */
    'address': UpdateCustomersAdminCommandAddress;
}
/**
 * 
 * @export
 * @interface ApiCustomersIdEmailPutRequest
 */
export interface ApiCustomersIdEmailPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersIdEmailPutRequest
     */
    'id': string;
    /**
     * 
     * @type {UpdateCustomerEmailDto}
     * @memberof ApiCustomersIdEmailPutRequest
     */
    'dto': UpdateCustomerEmailDto;
}
/**
 * 
 * @export
 * @interface ApiCustomersIdGetRequest
 */
export interface ApiCustomersIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersIdGetRequest
     */
    'id': string;
    /**
     * 
     * @type {UpdateCustomerDto}
     * @memberof ApiCustomersIdGetRequest
     */
    'dto': UpdateCustomerDto;
}
/**
 * 
 * @export
 * @interface ApiCustomersIdMobilenumberPutRequest
 */
export interface ApiCustomersIdMobilenumberPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersIdMobilenumberPutRequest
     */
    'id': string;
    /**
     * 
     * @type {UpdateCustomerMobileNumberDto}
     * @memberof ApiCustomersIdMobilenumberPutRequest
     */
    'dto': UpdateCustomerMobileNumberDto;
}
/**
 * 
 * @export
 * @interface ApiCustomersIdSendOtpPostRequest
 */
export interface ApiCustomersIdSendOtpPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersIdSendOtpPostRequest
     */
    'id': string;
    /**
     * 
     * @type {SendUpdateOtpDto}
     * @memberof ApiCustomersIdSendOtpPostRequest
     */
    'dto': SendUpdateOtpDto;
}
/**
 * 
 * @export
 * @interface ApiCustomersRegistrationPostRequest
 */
export interface ApiCustomersRegistrationPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersRegistrationPostRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersRegistrationPostRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomersRegistrationPostRequest
     */
    'customerId': string;
}
/**
 * 
 * @export
 * @interface ApiOrdersActivateOrderPutRequest
 */
export interface ApiOrdersActivateOrderPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersActivateOrderPutRequest
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersActivateOrderPutRequest
     */
    'activationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersActivateOrderPutRequest
     */
    'msisdn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersActivateOrderPutRequest
     */
    'productOrderReferenceNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiOrdersBypassTelkomSuspendOrderPutRequest
 */
export interface ApiOrdersBypassTelkomSuspendOrderPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersBypassTelkomSuspendOrderPutRequest
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface ApiOrdersGetRequest
 */
export interface ApiOrdersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersGetRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersGetRequest
     */
    'customerPaymentMethodId': string;
}
/**
 * 
 * @export
 * @interface ApiOrdersMigrateOrderPutRequest
 */
export interface ApiOrdersMigrateOrderPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersMigrateOrderPutRequest
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersMigrateOrderPutRequest
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersMigrateOrderPutRequest
     */
    'msisdn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersMigrateOrderPutRequest
     */
    'productOrderReferenceNumber': string;
}
/**
 * 
 * @export
 * @interface ApiOrdersOrderIdAddNewCardPostRequest
 */
export interface ApiOrdersOrderIdAddNewCardPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersOrderIdAddNewCardPostRequest
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersOrderIdAddNewCardPostRequest
     */
    'checkoutId': string;
}
/**
 * 
 * @export
 * @interface ApiOrdersPaymentRequestPostRequest
 */
export interface ApiOrdersPaymentRequestPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersPaymentRequestPostRequest
     */
    'ordersId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersPaymentRequestPostRequest
     */
    'orderProductsId': string;
    /**
     * 
     * @type {number}
     * @memberof ApiOrdersPaymentRequestPostRequest
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersPaymentRequestPostRequest
     */
    'requestedProcessDate'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiOrdersProcessPaymentsPostRequest
 */
export interface ApiOrdersProcessPaymentsPostRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiOrdersProcessPaymentsPostRequest
     */
    'orderIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersProcessPaymentsPostRequest
     */
    'billingDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiOrdersProcessPaymentsPostRequest
     */
    'amount'?: number | null;
}
/**
 * 
 * @export
 * @interface ApiOrdersProductsMsisdnCancelDeleteRequest
 */
export interface ApiOrdersProductsMsisdnCancelDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersProductsMsisdnCancelDeleteRequest
     */
    'msisdn': string;
    /**
     * 
     * @type {CancelationReasonsEnum}
     * @memberof ApiOrdersProductsMsisdnCancelDeleteRequest
     */
    'cancelationReasonsId': CancelationReasonsEnum;
}
/**
 * 
 * @export
 * @interface ApiOrdersProductsMsisdnTopupPutRequest
 */
export interface ApiOrdersProductsMsisdnTopupPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiOrdersProductsMsisdnTopupPutRequest
     */
    'productsId': number;
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersProductsMsisdnTopupPutRequest
     */
    'msisdn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiOrdersProductsMsisdnTopupPutRequest
     */
    'isTopUp': boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersProductsMsisdnTopupPutRequest
     */
    'ordersId': string;
}
/**
 * 
 * @export
 * @interface ApiOrdersReactivateOrderPutRequest
 */
export interface ApiOrdersReactivateOrderPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiOrdersReactivateOrderPutRequest
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface ApiPaymentsManualPaymentImportPutRequest
 */
export interface ApiPaymentsManualPaymentImportPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiPaymentsManualPaymentImportPutRequest
     */
    'relativeFileUrl': string;
}
/**
 * 
 * @export
 * @interface ApiPowerBiReportClaimGetRequest
 */
export interface ApiPowerBiReportClaimGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiPowerBiReportClaimGetRequest
     */
    'appplicationClaimTypesId': number;
    /**
     * 
     * @type {number}
     * @memberof ApiPowerBiReportClaimGetRequest
     */
    'powerBiReportsId': number;
}
/**
 * 
 * @export
 * @interface ApiPowerBiReportClaimIdDeleteRequest
 */
export interface ApiPowerBiReportClaimIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiPowerBiReportClaimIdDeleteRequest
     */
    'appplicationClaimTypesId': number;
    /**
     * 
     * @type {number}
     * @memberof ApiPowerBiReportClaimIdDeleteRequest
     */
    'powerBiReportsId': number;
    /**
     * 
     * @type {string}
     * @memberof ApiPowerBiReportClaimIdDeleteRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ApiPowerBiReportGetRequest
 */
export interface ApiPowerBiReportGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiPowerBiReportGetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApiPowerBiReportGetRequest
     */
    'reportId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiPowerBiReportGetRequest
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface ApiPowerBiReportIdDeleteRequest
 */
export interface ApiPowerBiReportIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiPowerBiReportIdDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApiPowerBiReportIdDeleteRequest
     */
    'reportId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiPowerBiReportIdDeleteRequest
     */
    'url': string;
    /**
     * 
     * @type {number}
     * @memberof ApiPowerBiReportIdDeleteRequest
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface ApiProductsGetRequest
 */
export interface ApiProductsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiProductsGetRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsGetRequest
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsGetRequest
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsGetRequest
     */
    'productType': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsGetRequest
     */
    'providerDealId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsGetRequest
     */
    'providerPackageName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsGetRequest
     */
    'providerDealDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ApiProductsGetRequest
     */
    'providerCost': number;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsGetRequest
     */
    'vascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsGetRequest
     */
    'vascoDealDescription': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsGetRequest
     */
    'vascoPackageDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ApiProductsGetRequest
     */
    'vascoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ApiProductsGetRequest
     */
    'adminFee': number;
    /**
     * 
     * @type {number}
     * @memberof ApiProductsGetRequest
     */
    'agentCommission': number;
    /**
     * 
     * @type {number}
     * @memberof ApiProductsGetRequest
     */
    'dealerCommission': number;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsGetRequest
     */
    'contractPeriod'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiProductsGetRequest
     */
    'isTopUp': boolean;
}
/**
 * 
 * @export
 * @interface ApiProductsIdGetRequest
 */
export interface ApiProductsIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiProductsIdGetRequest
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsIdGetRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsIdGetRequest
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsIdGetRequest
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsIdGetRequest
     */
    'productType': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsIdGetRequest
     */
    'providerDealId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsIdGetRequest
     */
    'providerPackageName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsIdGetRequest
     */
    'providerDealDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ApiProductsIdGetRequest
     */
    'providerCost': number;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsIdGetRequest
     */
    'vascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsIdGetRequest
     */
    'vascoDealDescription': string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsIdGetRequest
     */
    'vascoPackageDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ApiProductsIdGetRequest
     */
    'vascoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ApiProductsIdGetRequest
     */
    'adminFee': number;
    /**
     * 
     * @type {number}
     * @memberof ApiProductsIdGetRequest
     */
    'agentCommission': number;
    /**
     * 
     * @type {number}
     * @memberof ApiProductsIdGetRequest
     */
    'dealerCommission': number;
    /**
     * 
     * @type {string}
     * @memberof ApiProductsIdGetRequest
     */
    'contractPeriod'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiProductsIdGetRequest
     */
    'isTopUp': boolean;
}
/**
 * 
 * @export
 * @interface ApiRolesDeleteRequest
 */
export interface ApiRolesDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiRolesDeleteRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiRolesDeleteRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<ApplicationClaimType>}
     * @memberof ApiRolesDeleteRequest
     */
    'claims': Array<ApplicationClaimType>;
}
/**
 * 
 * @export
 * @interface ApiRolesDeleteRequest1
 */
export interface ApiRolesDeleteRequest1 {
    /**
     * 
     * @type {string}
     * @memberof ApiRolesDeleteRequest1
     */
    'name': string;
    /**
     * 
     * @type {Array<ApplicationClaimType>}
     * @memberof ApiRolesDeleteRequest1
     */
    'claims': Array<ApplicationClaimType>;
}
/**
 * 
 * @export
 * @interface ApiUsersGetRequest
 */
export interface ApiUsersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiUsersGetRequest
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiUsersGetRequest
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiUsersGetRequest
     */
    'roleIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiUsersGetRequest
     */
    'password'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiUsersIdDeleteRequest
 */
export interface ApiUsersIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiUsersIdDeleteRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApiUsersIdDeleteRequest
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof ApiUsersIdDeleteRequest
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiUsersIdDeleteRequest
     */
    'roleIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiUsersIdDeleteRequest
     */
    'password'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiUsersIdDeleteRequest
     */
    'isEnabled'?: boolean | null;
}
/**
 * Keep in sync with DB table via seeding
 * @export
 * @enum {string}
 */

export const ApplicationClaimType = {
    SearchCustomer: 'SearchCustomer',
    ViewCustomer: 'ViewCustomer',
    ManageUsers: 'ManageUsers',
    ManageClaims: 'ManageClaims',
    ManageCustomer: 'ManageCustomer',
    ViewOrders: 'ViewOrders',
    ManageOrders: 'ManageOrders'
} as const;

export type ApplicationClaimType = typeof ApplicationClaimType[keyof typeof ApplicationClaimType];


/**
 * 
 * @export
 * @interface AuditLogDto
 */
export interface AuditLogDto {
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'oldValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'newValue': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'typePrimaryKey': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'createdByUserDisplayName': string;
    /**
     * 
     * @type {AuditTypes}
     * @memberof AuditLogDto
     */
    'auditLogTypesId': AuditTypes;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AuditTypes = {
    Users: 'Users',
    ApplicationUserRoles: 'ApplicationUserRoles',
    Orders: 'Orders',
    OrderProducts: 'OrderProducts',
    Products: 'Products',
    OrderHistory: 'OrderHistory',
    OrderPayment: 'OrderPayment',
    OrderInvoices: 'OrderInvoices',
    Customers: 'Customers',
    OrderCost: 'OrderCost',
    BillingCycle: 'BillingCycle'
} as const;

export type AuditTypes = typeof AuditTypes[keyof typeof AuditTypes];


/**
 * 
 * @export
 * @interface BillingCycleViewModel
 */
export interface BillingCycleViewModel {
    /**
     * 
     * @type {string}
     * @memberof BillingCycleViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BillingCycleViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof BillingCycleViewModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof BillingCycleViewModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof BillingCycleViewModel
     */
    'ordersId': string;
    /**
     * 
     * @type {Array<OrderCostViewModel>}
     * @memberof BillingCycleViewModel
     */
    'orderCosts': Array<OrderCostViewModel>;
}
/**
 * 
 * @export
 * @interface BillingDaysListViewModel
 */
export interface BillingDaysListViewModel {
    /**
     * 
     * @type {string}
     * @memberof BillingDaysListViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BillingDaysListViewModel
     */
    'dayOfTheMonth': number;
}
/**
 * 
 * @export
 * @interface BypassTelkomSuspendOrder
 */
export interface BypassTelkomSuspendOrder {
    /**
     * 
     * @type {string}
     * @memberof BypassTelkomSuspendOrder
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface CancelOrder
 */
export interface CancelOrder {
    /**
     * 
     * @type {string}
     * @memberof CancelOrder
     */
    'msisdn': string;
    /**
     * 
     * @type {CancelationReasonsEnum}
     * @memberof CancelOrder
     */
    'cancelationReasonsId': CancelationReasonsEnum;
}
/**
 * 
 * @export
 * @interface CancelationReasonsDto
 */
export interface CancelationReasonsDto {
    /**
     * 
     * @type {number}
     * @memberof CancelationReasonsDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CancelationReasonsDto
     */
    'reason': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CancelationReasonsEnum = {
    NoLongerUsesProduct: 'NoLongerUsesProduct',
    CannotAfford: 'CannotAfford',
    PoorServiceOrCoverage: 'PoorServiceOrCoverage',
    NonPayment: 'NonPayment',
    TheftOrLossOfTheSim: 'TheftOrLossOfTheSim',
    PrefersCompetitorProduct: 'PrefersCompetitorProduct',
    ServiceRelated: 'ServiceRelated',
    NoCoverage: 'NoCoverage',
    FraudulentOrder: 'FraudulentOrder',
    CustomerDeceased: 'CustomerDeceased',
    DuplicateOrder: 'DuplicateOrder',
    OrderAbandoned: 'OrderAbandoned',
    UnableToActivateOnNetwork: 'UnableToActivateOnNetwork'
} as const;

export type CancelationReasonsEnum = typeof CancelationReasonsEnum[keyof typeof CancelationReasonsEnum];


/**
 * 
 * @export
 * @interface ClaimListViewModel
 */
export interface ClaimListViewModel {
    /**
     * 
     * @type {number}
     * @memberof ClaimListViewModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ClaimListViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ConfirmEmailDto
 */
export interface ConfirmEmailDto {
    /**
     * 
     * @type {string}
     * @memberof ConfirmEmailDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmEmailDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface CreateBillingCycleCommand
 */
export interface CreateBillingCycleCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateBillingCycleCommand
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillingCycleCommand
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillingCycleCommand
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillingCycleCommand
     */
    'ordersId': string;
}
/**
 * 
 * @export
 * @interface CreateCustomerPaymentMethodCommand
 */
export interface CreateCustomerPaymentMethodCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerPaymentMethodCommand
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface CreateOrderPaymentRequestCommand
 */
export interface CreateOrderPaymentRequestCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPaymentRequestCommand
     */
    'ordersId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPaymentRequestCommand
     */
    'orderProductsId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderPaymentRequestCommand
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPaymentRequestCommand
     */
    'requestedProcessDate'?: string | null;
}
/**
 * 
 * @export
 * @interface CreatePowerBiReportClaimCommand
 */
export interface CreatePowerBiReportClaimCommand {
    /**
     * 
     * @type {number}
     * @memberof CreatePowerBiReportClaimCommand
     */
    'appplicationClaimTypesId': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePowerBiReportClaimCommand
     */
    'powerBiReportsId': number;
}
/**
 * 
 * @export
 * @interface CreatePowerBiReportsCommand
 */
export interface CreatePowerBiReportsCommand {
    /**
     * 
     * @type {string}
     * @memberof CreatePowerBiReportsCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePowerBiReportsCommand
     */
    'reportId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePowerBiReportsCommand
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface CreateRoleCommand
 */
export interface CreateRoleCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateRoleCommand
     */
    'name': string;
    /**
     * 
     * @type {Array<ApplicationClaimType>}
     * @memberof CreateRoleCommand
     */
    'claims': Array<ApplicationClaimType>;
}
/**
 * 
 * @export
 * @interface CreateUpdateCustomersAddressessDto
 */
export interface CreateUpdateCustomersAddressessDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomersAddressessDto
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomersAddressessDto
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomersAddressessDto
     */
    'line3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomersAddressessDto
     */
    'suburb': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomersAddressessDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomersAddressessDto
     */
    'province'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomersAddressessDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCustomersAddressessDto
     */
    'postalCode': string;
}
/**
 * 
 * @export
 * @interface CreateUsersCommand
 */
export interface CreateUsersCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateUsersCommand
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUsersCommand
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUsersCommand
     */
    'roleIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateUsersCommand
     */
    'password'?: string | null;
}
/**
 * 
 * @export
 * @interface CurrentAndFutureBillingCycleViewModel
 */
export interface CurrentAndFutureBillingCycleViewModel {
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'billingDay': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'nextPaymentAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'customersId': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'preferredUpfrontPayementDate': string;
    /**
     * 
     * @type {number}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'prorataAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'customersFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'customersLastName': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'nextBillingDay': string;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'isNewCustomer': boolean;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'paymentLastScheduledDate'?: string | null;
    /**
     * 
     * @type {Array<BillingCycleViewModel>}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'billingCycles': Array<BillingCycleViewModel>;
    /**
     * 
     * @type {OrderSubStatesEnum}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'orderSubStateId': OrderSubStatesEnum;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'currentBillingCycleStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'currentBillingCycleEndDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'previousPaymentStatus': boolean;
    /**
     * 
     * @type {number}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'previousPaymentAmount': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'futurePaymentAmount'?: number | null;
    /**
     * 
     * @type {Array<OrderPaymentViewModel>}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'orderPayments': Array<OrderPaymentViewModel>;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'previousBillingCycleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'currentBillingCycleId': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'nextBillingCycleId': string;
    /**
     * 
     * @type {CurrentAndFutureBillingCycleViewModelPreviousBillingCycle}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'previousBillingCycle'?: CurrentAndFutureBillingCycleViewModelPreviousBillingCycle | null;
    /**
     * 
     * @type {CurrentAndFutureBillingCycleViewModelCurrentBillingCycle}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'currentBillingCycle': CurrentAndFutureBillingCycleViewModelCurrentBillingCycle;
    /**
     * 
     * @type {CurrentAndFutureBillingCycleViewModelCurrentBillingCycle}
     * @memberof CurrentAndFutureBillingCycleViewModel
     */
    'nextBillingCycle': CurrentAndFutureBillingCycleViewModelCurrentBillingCycle;
}
/**
 * 
 * @export
 * @interface CurrentAndFutureBillingCycleViewModelCurrentBillingCycle
 */
export interface CurrentAndFutureBillingCycleViewModelCurrentBillingCycle {
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModelCurrentBillingCycle
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModelCurrentBillingCycle
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModelCurrentBillingCycle
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModelCurrentBillingCycle
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModelCurrentBillingCycle
     */
    'ordersId': string;
    /**
     * 
     * @type {Array<OrderCostViewModel>}
     * @memberof CurrentAndFutureBillingCycleViewModelCurrentBillingCycle
     */
    'orderCosts': Array<OrderCostViewModel>;
}
/**
 * 
 * @export
 * @interface CurrentAndFutureBillingCycleViewModelPreviousBillingCycle
 */
export interface CurrentAndFutureBillingCycleViewModelPreviousBillingCycle {
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModelPreviousBillingCycle
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModelPreviousBillingCycle
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModelPreviousBillingCycle
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModelPreviousBillingCycle
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof CurrentAndFutureBillingCycleViewModelPreviousBillingCycle
     */
    'ordersId': string;
    /**
     * 
     * @type {Array<OrderCostViewModel>}
     * @memberof CurrentAndFutureBillingCycleViewModelPreviousBillingCycle
     */
    'orderCosts': Array<OrderCostViewModel>;
}
/**
 * 
 * @export
 * @interface CustomerOrderInvoicesDto
 */
export interface CustomerOrderInvoicesDto {
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderInvoicesDto
     */
    'invoiceNumber': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderInvoicesDto
     */
    'invoiceDate': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderInvoicesDto
     */
    'dueDate': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderInvoicesDto
     */
    'totalAmount': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderInvoicesDto
     */
    'paymentMethod': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderInvoicesDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderInvoicesDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CustomerOrdersDto
 */
export interface CustomerOrdersDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDto
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {Array<OrderProductsDto>}
     * @memberof CustomerOrdersDto
     */
    'orderProducts': Array<OrderProductsDto>;
    /**
     * 
     * @type {Array<OrderPaymentDto>}
     * @memberof CustomerOrdersDto
     */
    'payments': Array<OrderPaymentDto>;
    /**
     * 
     * @type {CustomerOrdersDtoCustomerPaymentMethods}
     * @memberof CustomerOrdersDto
     */
    'customerPaymentMethods'?: CustomerOrdersDtoCustomerPaymentMethods | null;
    /**
     * 
     * @type {OrderSubStatesEnum}
     * @memberof CustomerOrdersDto
     */
    'orderSubStateId': OrderSubStatesEnum;
    /**
     * 
     * @type {Array<CustomerOrderInvoicesDto>}
     * @memberof CustomerOrdersDto
     */
    'orderInvoices': Array<CustomerOrderInvoicesDto>;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDto
     */
    'nextBillingDate': string;
}
/**
 * 
 * @export
 * @interface CustomerOrdersDtoCustomerPaymentMethods
 */
export interface CustomerOrdersDtoCustomerPaymentMethods {
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDtoCustomerPaymentMethods
     */
    'cardId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDtoCustomerPaymentMethods
     */
    'customersId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDtoCustomerPaymentMethods
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDtoCustomerPaymentMethods
     */
    'cardType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDtoCustomerPaymentMethods
     */
    'last4Digits'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDtoCustomerPaymentMethods
     */
    'bin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDtoCustomerPaymentMethods
     */
    'binCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDtoCustomerPaymentMethods
     */
    'holder'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDtoCustomerPaymentMethods
     */
    'expiryMonth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDtoCustomerPaymentMethods
     */
    'expiryYear'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDtoCustomerPaymentMethods
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersDtoCustomerPaymentMethods
     */
    'tokenizeTransactionId'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerPaymentMethodDto
 */
export interface CustomerPaymentMethodDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodDto
     */
    'paymentMethod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodDto
     */
    'last4Digits'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodDto
     */
    'bin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodDto
     */
    'binCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodDto
     */
    'holder'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodDto
     */
    'expiryMonth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodDto
     */
    'expiryYear'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPaymentMethodDto
     */
    'isPendingRegistration': boolean;
}
/**
 * 
 * @export
 * @interface CustomerPaymentMethodsDto
 */
export interface CustomerPaymentMethodsDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsDto
     */
    'cardId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsDto
     */
    'customersId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsDto
     */
    'cardType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsDto
     */
    'last4Digits'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsDto
     */
    'bin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsDto
     */
    'binCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsDto
     */
    'holder'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsDto
     */
    'expiryMonth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsDto
     */
    'expiryYear'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsDto
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsDto
     */
    'tokenizeTransactionId'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerPaymentMethodsViewModel
 */
export interface CustomerPaymentMethodsViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'cardId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'customersId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'paymentMethod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'tokenizeTransactionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'cardType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'last4Digits'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'bin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'binCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'holder'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'expiryMonth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentMethodsViewModel
     */
    'expiryYear'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerType = {
    Customer: 'Customer',
    Corporate: 'Corporate'
} as const;

export type CustomerType = typeof CustomerType[keyof typeof CustomerType];


/**
 * 
 * @export
 * @interface CustomerUserRegistration
 */
export interface CustomerUserRegistration {
    /**
     * 
     * @type {string}
     * @memberof CustomerUserRegistration
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserRegistration
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserRegistration
     */
    'customerId': string;
}
/**
 * 
 * @export
 * @interface CustomersDto
 */
export interface CustomersDto {
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'idNationality': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'mobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'additionalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'suspendedDate'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof CustomersDto
     */
    'gender': Gender;
    /**
     * 
     * @type {IDType}
     * @memberof CustomersDto
     */
    'idType': IDType;
    /**
     * 
     * @type {CustomerType}
     * @memberof CustomersDto
     */
    'customerType': CustomerType;
    /**
     * 
     * @type {Array<CustomerPaymentMethodsDto>}
     * @memberof CustomersDto
     */
    'customerPaymentMethods'?: Array<CustomerPaymentMethodsDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersDto
     */
    'usersId'?: string | null;
    /**
     * 
     * @type {Array<OrdersDto>}
     * @memberof CustomersDto
     */
    'orders': Array<OrdersDto>;
    /**
     * 
     * @type {boolean}
     * @memberof CustomersDto
     */
    'isAccountOtpValidated'?: boolean | null;
    /**
     * 
     * @type {CustomersDtoAddress}
     * @memberof CustomersDto
     */
    'address': CustomersDtoAddress;
}
/**
 * 
 * @export
 * @interface CustomersDtoAddress
 */
export interface CustomersDtoAddress {
    /**
     * 
     * @type {string}
     * @memberof CustomersDtoAddress
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDtoAddress
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersDtoAddress
     */
    'line3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersDtoAddress
     */
    'suburb': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDtoAddress
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDtoAddress
     */
    'province'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersDtoAddress
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDtoAddress
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDtoAddress
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersDtoAddress
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersDtoAddress
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CustomersUpdateDto
 */
export interface CustomersUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDto
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDto
     */
    'lastName': string;
    /**
     * 
     * @type {CustomersUpdateDtoAddress}
     * @memberof CustomersUpdateDto
     */
    'address': CustomersUpdateDtoAddress;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDto
     */
    'additionalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDto
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDto
     */
    'currentPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDto
     */
    'newPassword'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof CustomersUpdateDto
     */
    'gender': Gender;
}
/**
 * 
 * @export
 * @interface CustomersUpdateDtoAddress
 */
export interface CustomersUpdateDtoAddress {
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDtoAddress
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDtoAddress
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDtoAddress
     */
    'line3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDtoAddress
     */
    'suburb': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDtoAddress
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDtoAddress
     */
    'province'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDtoAddress
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateDtoAddress
     */
    'postalCode': string;
}
/**
 * 
 * @export
 * @interface CustomersUpdateEmailDto
 */
export interface CustomersUpdateEmailDto {
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateEmailDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateEmailDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateEmailDto
     */
    'otp': string;
}
/**
 * 
 * @export
 * @interface CustomersUpdateMobileNumberDto
 */
export interface CustomersUpdateMobileNumberDto {
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateMobileNumberDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateMobileNumberDto
     */
    'mobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersUpdateMobileNumberDto
     */
    'otp': string;
}
/**
 * 
 * @export
 * @interface CustomersViewModel
 */
export interface CustomersViewModel {
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'idNationality': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'mobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'additionalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'suspendedDate'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof CustomersViewModel
     */
    'gender': Gender;
    /**
     * 
     * @type {IDType}
     * @memberof CustomersViewModel
     */
    'idType': IDType;
    /**
     * 
     * @type {CustomerType}
     * @memberof CustomersViewModel
     */
    'customerType': CustomerType;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'usersId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomersViewModel
     */
    'isAccountOtpValidated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CustomersViewModel
     */
    'id': string;
    /**
     * 
     * @type {Array<CustomerPaymentMethodsViewModel>}
     * @memberof CustomersViewModel
     */
    'customerPaymentMethods': Array<CustomerPaymentMethodsViewModel>;
    /**
     * 
     * @type {CustomersUpdateDtoAddress}
     * @memberof CustomersViewModel
     */
    'address': CustomersUpdateDtoAddress;
}
/**
 * 
 * @export
 * @interface FilePath
 */
export interface FilePath {
    /**
     * 
     * @type {string}
     * @memberof FilePath
     */
    'relativePath'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FilePath
     */
    'absolutePath': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Gender = {
    Male: 'Male',
    Female: 'Female'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];


/**
 * 
 * @export
 * @interface GetProdcutOrderStatusDto
 */
export interface GetProdcutOrderStatusDto {
    /**
     * 
     * @type {string}
     * @memberof GetProdcutOrderStatusDto
     */
    'externalId': string;
    /**
     * 
     * @type {string}
     * @memberof GetProdcutOrderStatusDto
     */
    'productOrderReferenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof GetProdcutOrderStatusDto
     */
    'subscriberStatus': string;
    /**
     * 
     * @type {string}
     * @memberof GetProdcutOrderStatusDto
     */
    'OrderStatus': string;
    /**
     * 
     * @type {string}
     * @memberof GetProdcutOrderStatusDto
     */
    'OrderState': string;
    /**
     * 
     * @type {string}
     * @memberof GetProdcutOrderStatusDto
     */
    'CreatedDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetProdcutOrderStatusDto
     */
    'DueDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetProdcutOrderStatusDto
     */
    'UpdatedDate': string;
}
/**
 * 
 * @export
 * @interface GetServiceDto
 */
export interface GetServiceDto {
    /**
     * 
     * @type {GetServiceDtoRatePlan}
     * @memberof GetServiceDto
     */
    'ratePlan': GetServiceDtoRatePlan;
    /**
     * 
     * @type {GetServiceDtoSubscriberInfo}
     * @memberof GetServiceDto
     */
    'subscriberInfo': GetServiceDtoSubscriberInfo;
}
/**
 * 
 * @export
 * @interface GetServiceDtoRatePlan
 */
export interface GetServiceDtoRatePlan {
    /**
     * 
     * @type {string}
     * @memberof GetServiceDtoRatePlan
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof GetServiceDtoRatePlan
     */
    'effectiveDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetServiceDtoRatePlan
     */
    'expiryDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetServiceDtoRatePlan
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetServiceDtoRatePlan
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface GetServiceDtoSubscriberInfo
 */
export interface GetServiceDtoSubscriberInfo {
    /**
     * 
     * @type {string}
     * @memberof GetServiceDtoSubscriberInfo
     */
    'subscriberStatus': string;
    /**
     * 
     * @type {string}
     * @memberof GetServiceDtoSubscriberInfo
     */
    'subscriberType': string;
    /**
     * 
     * @type {string}
     * @memberof GetServiceDtoSubscriberInfo
     */
    'suspendedReason': string;
    /**
     * 
     * @type {string}
     * @memberof GetServiceDtoSubscriberInfo
     */
    'iccid': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IDType = {
    Passport: 'Passport',
    IdNumber: 'IDNumber'
} as const;

export type IDType = typeof IDType[keyof typeof IDType];


/**
 * 
 * @export
 * @interface ImportDto
 */
export interface ImportDto {
    /**
     * 
     * @type {string}
     * @memberof ImportDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportDto
     */
    'fileRelativeUrl': string;
    /**
     * 
     * @type {ImportStatus}
     * @memberof ImportDto
     */
    'importStatusId': ImportStatus;
    /**
     * 
     * @type {Array<ImportImportErrorDto>}
     * @memberof ImportDto
     */
    'importErrors': Array<ImportImportErrorDto>;
}
/**
 * 
 * @export
 * @interface ImportImportErrorDto
 */
export interface ImportImportErrorDto {
    /**
     * 
     * @type {string}
     * @memberof ImportImportErrorDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportImportErrorDto
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ImportImportErrorDto
     */
    'exception'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportImportErrorDto
     */
    'importId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ImportStatus = {
    New: 'New',
    Pending: 'Pending',
    InProgress: 'InProgress',
    Complete: 'Complete',
    Error: 'Error'
} as const;

export type ImportStatus = typeof ImportStatus[keyof typeof ImportStatus];


/**
 * Implicit wrapping of types that serialize to non-complex values.
 * @export
 * @interface JsonResponseOfGuid
 */
export interface JsonResponseOfGuid {
    /**
     * 
     * @type {string}
     * @memberof JsonResponseOfGuid
     */
    'value': string;
}
/**
 * Implicit wrapping of types that serialize to non-complex values.
 * @export
 * @interface JsonResponseOfInt32
 */
export interface JsonResponseOfInt32 {
    /**
     * 
     * @type {number}
     * @memberof JsonResponseOfInt32
     */
    'value': number;
}
/**
 * Implicit wrapping of types that serialize to non-complex values.
 * @export
 * @interface JsonResponseOfListOfString
 */
export interface JsonResponseOfListOfString {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonResponseOfListOfString
     */
    'value': Array<string>;
}
/**
 * Implicit wrapping of types that serialize to non-complex values.
 * @export
 * @interface JsonResponseOfString
 */
export interface JsonResponseOfString {
    /**
     * 
     * @type {string}
     * @memberof JsonResponseOfString
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ManualPaymentImportCommand
 */
export interface ManualPaymentImportCommand {
    /**
     * 
     * @type {string}
     * @memberof ManualPaymentImportCommand
     */
    'relativeFileUrl': string;
}
/**
 * 
 * @export
 * @interface MarkOrderAsMigrated
 */
export interface MarkOrderAsMigrated {
    /**
     * 
     * @type {string}
     * @memberof MarkOrderAsMigrated
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof MarkOrderAsMigrated
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarkOrderAsMigrated
     */
    'msisdn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarkOrderAsMigrated
     */
    'productOrderReferenceNumber': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MessageType = {
    Email: 'Email',
    Sms: 'SMS'
} as const;

export type MessageType = typeof MessageType[keyof typeof MessageType];


/**
 * 
 * @export
 * @interface OkResult
 */
export interface OkResult {
    /**
     * 
     * @type {number}
     * @memberof OkResult
     */
    'statusCode': number;
}
/**
 * 
 * @export
 * @interface OrderCostViewModel
 */
export interface OrderCostViewModel {
    /**
     * 
     * @type {number}
     * @memberof OrderCostViewModel
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCostViewModel
     */
    'orderProductsId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCostViewModel
     */
    'billingCycleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCostViewModel
     */
    'ordersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCostViewModel
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface OrderCostsUpdateViewModel
 */
export interface OrderCostsUpdateViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderCostsUpdateViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCostsUpdateViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCostsUpdateViewModel
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCostsUpdateViewModel
     */
    'orderProductsId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCostsUpdateViewModel
     */
    'billingCycleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderCostsUpdateViewModel
     */
    'ordersId': string;
}
/**
 * 
 * @export
 * @interface OrderInvoiceHtmlDto
 */
export interface OrderInvoiceHtmlDto {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceHtmlDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceHtmlDto
     */
    'html': string;
}
/**
 * 
 * @export
 * @interface OrderInvoiceProductsDto
 */
export interface OrderInvoiceProductsDto {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDto
     */
    'orderInvoicesId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDto
     */
    'orderProductsId': string;
    /**
     * 
     * @type {OrderInvoiceProductsDtoOrderInvoices}
     * @memberof OrderInvoiceProductsDto
     */
    'orderInvoices': OrderInvoiceProductsDtoOrderInvoices;
    /**
     * 
     * @type {OrderInvoiceProductsDtoOrderProducts}
     * @memberof OrderInvoiceProductsDto
     */
    'orderProducts': OrderInvoiceProductsDtoOrderProducts;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface OrderInvoiceProductsDtoOrderInvoices
 */
export interface OrderInvoiceProductsDtoOrderInvoices {
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'invoiceNumber': number;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'invoiceDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'dueDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'customersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'ordersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'orderDate': string;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'subtotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'tax': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'totalAmount': number;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'paymentMethod': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'recurringDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'recurringAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderInvoices
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface OrderInvoiceProductsDtoOrderProducts
 */
export interface OrderInvoiceProductsDtoOrderProducts {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'vascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'productsId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'msisdn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'iccid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'netIccid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'subscriberStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'activationStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'orderStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'orderState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'productOrderReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'prodcutDesctiption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'dueDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'previousOrderProductsId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'changeSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'changeRequestStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'changeStamp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'isTopUp': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'ordersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'id': string;
    /**
     * 
     * @type {OrderInvoiceProductsOrderProductsDtoProducts}
     * @memberof OrderInvoiceProductsDtoOrderProducts
     */
    'products': OrderInvoiceProductsOrderProductsDtoProducts;
}
/**
 * 
 * @export
 * @interface OrderInvoiceProductsOrderInvoicesDto
 */
export interface OrderInvoiceProductsOrderInvoicesDto {
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'invoiceNumber': number;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'invoiceDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'dueDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'customersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'ordersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'orderDate': string;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'subtotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'tax': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'totalAmount': number;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'paymentMethod': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'recurringDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'recurringAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderInvoicesDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface OrderInvoiceProductsOrderProductsDto
 */
export interface OrderInvoiceProductsOrderProductsDto {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'vascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'productsId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'msisdn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'iccid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'netIccid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'subscriberStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'activationStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'orderStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'orderState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'productOrderReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'prodcutDesctiption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'dueDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'previousOrderProductsId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'changeSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'changeRequestStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'changeStamp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'isTopUp': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'ordersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'id': string;
    /**
     * 
     * @type {OrderInvoiceProductsOrderProductsDtoProducts}
     * @memberof OrderInvoiceProductsOrderProductsDto
     */
    'products': OrderInvoiceProductsOrderProductsDtoProducts;
}
/**
 * 
 * @export
 * @interface OrderInvoiceProductsOrderProductsDtoProducts
 */
export interface OrderInvoiceProductsOrderProductsDtoProducts {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'productType': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'providerDealId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'providerPackageName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'providerDealDescription': string;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'providerCost': number;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'vascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'vascoDealDescription': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'vascoPackageDescription': string;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'vascoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'adminFee': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'agentCommission': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'dealerCommission': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'isTopUp': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'contractPeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderProductsDtoProducts
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface OrderInvoiceProductsOrderProductsProductsDto
 */
export interface OrderInvoiceProductsOrderProductsProductsDto {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'productType': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'providerDealId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'providerPackageName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'providerDealDescription': string;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'providerCost': number;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'vascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'vascoDealDescription': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'vascoPackageDescription': string;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'vascoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'adminFee': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'agentCommission': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'dealerCommission': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'isTopUp': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'contractPeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoiceProductsOrderProductsProductsDto
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface OrderInvoicesDto
 */
export interface OrderInvoicesDto {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoicesDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoicesDto
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoicesDto
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoicesDto
     */
    'invoiceNumber': number;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoicesDto
     */
    'invoiceDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoicesDto
     */
    'dueDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoicesDto
     */
    'customersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoicesDto
     */
    'ordersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoicesDto
     */
    'orderDate': string;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoicesDto
     */
    'subtotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoicesDto
     */
    'tax': number;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoicesDto
     */
    'totalAmount': number;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoicesDto
     */
    'paymentMethod': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoicesDto
     */
    'recurringDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoicesDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderInvoicesDto
     */
    'recurringAmount'?: number | null;
    /**
     * 
     * @type {Array<OrderInvoiceProductsDto>}
     * @memberof OrderInvoicesDto
     */
    'orderProducts': Array<OrderInvoiceProductsDto>;
}
/**
 * 
 * @export
 * @interface OrderMessagesDto
 */
export interface OrderMessagesDto {
    /**
     * 
     * @type {string}
     * @memberof OrderMessagesDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderMessagesDto
     */
    'ordersId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderMessagesDto
     */
    'simpleMessagesMessageID': number;
    /**
     * 
     * @type {string}
     * @memberof OrderMessagesDto
     */
    'simpleMessagesToAddress': string;
    /**
     * 
     * @type {string}
     * @memberof OrderMessagesDto
     */
    'simpleMessagesToName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderMessagesDto
     */
    'simpleMessagesSubject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderMessagesDto
     */
    'simpleMessagesBody'?: string | null;
    /**
     * 
     * @type {MessageType}
     * @memberof OrderMessagesDto
     */
    'messageType': MessageType;
    /**
     * 
     * @type {boolean}
     * @memberof OrderMessagesDto
     */
    'simpleMessagesIsHtml': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderMessagesDto
     */
    'simpleMessagesDateCreated': string;
    /**
     * 
     * @type {string}
     * @memberof OrderMessagesDto
     */
    'resendDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderMessagesDto
     */
    'originalOrderMessageId'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPaymentDto
 */
export interface OrderPaymentDto {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'ordersId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'orderPaymentRequestId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'paymentProviderPayload': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'paymentProviderWebhookPayload'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'customerPaymentMethodsId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPaymentDto
     */
    'isSuccess'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentDto
     */
    'paymentProviderId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'paymentDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'orderProductsId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'paymentProviderReference': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'paymentFailureReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'customerPaymentMethodsCardType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'customerPaymentMethodsLast4Digits'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'customerPaymentMethodsHolder'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'customerPaymentMethodsExpiryMonth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'customerPaymentMethodsExpiryYear'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentDto
     */
    'productsVascoPrice': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'productsVascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'productsVascoDealDescription': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'productsVascoPackageDescription': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentDto
     */
    'customerPaymentMethodsCardId': string;
}
/**
 * 
 * @export
 * @interface OrderPaymentRequestDto
 */
export interface OrderPaymentRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentRequestDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentRequestDto
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentRequestDto
     */
    'paymentRequestProviderId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentRequestDto
     */
    'requestedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentRequestDto
     */
    'ordersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentRequestDto
     */
    'orderProductsId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentRequestDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentRequestDto
     */
    'paymentRequestProviderReference': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentRequestDto
     */
    'requestedProcessDate'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderPaymentViewModel
 */
export interface OrderPaymentViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'ordersId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentViewModel
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'orderPaymentRequestId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'paymentProviderReference': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'paymentProviderResponse': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'paymentProviderWebhookResponse'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'customerPaymentMethodsId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPaymentViewModel
     */
    'isSuccess'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPaymentViewModel
     */
    'paymentProviderId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'paymentDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'orderProductsId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'paymentFailureReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'customerPaymentMethodsPaymentMethod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'customerPaymentMethodsCardType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'customerPaymentMethodsLast4Digits'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'customerPaymentMethodsBin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'customerPaymentMethodsBinCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'customerPaymentMethodsHolder'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'customerPaymentMethodsExpiryMonth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'customerPaymentMethodsExpiryYear'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModel
     */
    'paymentType': string;
    /**
     * 
     * @type {OrderPaymentViewModelFailedPaymentResult}
     * @memberof OrderPaymentViewModel
     */
    'failedPaymentResult'?: OrderPaymentViewModelFailedPaymentResult | null;
}
/**
 * 
 * @export
 * @interface OrderPaymentViewModelFailedPaymentResult
 */
export interface OrderPaymentViewModelFailedPaymentResult {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModelFailedPaymentResult
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentViewModelFailedPaymentResult
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface OrderProductGroupedQueuesViewModel
 */
export interface OrderProductGroupedQueuesViewModel {
    /**
     * 
     * @type {OrderProductGroupedQueuesViewModelOrderProductQueues}
     * @memberof OrderProductGroupedQueuesViewModel
     */
    'orderProductQueues': OrderProductGroupedQueuesViewModelOrderProductQueues;
    /**
     * 
     * @type {Array<QueueStatus>}
     * @memberof OrderProductGroupedQueuesViewModel
     */
    'newOrders': Array<QueueStatus>;
    /**
     * 
     * @type {Array<QueueStatus>}
     * @memberof OrderProductGroupedQueuesViewModel
     */
    'activeOrders': Array<QueueStatus>;
    /**
     * 
     * @type {Array<QueueStatus>}
     * @memberof OrderProductGroupedQueuesViewModel
     */
    'suspendedOrders': Array<QueueStatus>;
    /**
     * 
     * @type {Array<QueueStatus>}
     * @memberof OrderProductGroupedQueuesViewModel
     */
    'cancelledOrders': Array<QueueStatus>;
}
/**
 * 
 * @export
 * @interface OrderProductGroupedQueuesViewModelOrderProductQueues
 */
export interface OrderProductGroupedQueuesViewModelOrderProductQueues {
    /**
     * 
     * @type {number}
     * @memberof OrderProductGroupedQueuesViewModelOrderProductQueues
     */
    'paymentDue': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductGroupedQueuesViewModelOrderProductQueues
     */
    'paidUp': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductGroupedQueuesViewModelOrderProductQueues
     */
    'pendingSuspension': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductGroupedQueuesViewModelOrderProductQueues
     */
    'pendingCancelation': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductGroupedQueuesViewModelOrderProductQueues
     */
    'suspendTelkomErrors': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductGroupedQueuesViewModelOrderProductQueues
     */
    'cancelTelkomErrors': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductGroupedQueuesViewModelOrderProductQueues
     */
    'pendingTelkomActivation': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductGroupedQueuesViewModelOrderProductQueues
     */
    'newlyActivated': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductGroupedQueuesViewModelOrderProductQueues
     */
    'pendingInitialPayment': number;
}
/**
 * 
 * @export
 * @interface OrderProductQueuesViewModel
 */
export interface OrderProductQueuesViewModel {
    /**
     * 
     * @type {number}
     * @memberof OrderProductQueuesViewModel
     */
    'paymentDue': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductQueuesViewModel
     */
    'paidUp': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductQueuesViewModel
     */
    'pendingSuspension': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductQueuesViewModel
     */
    'pendingCancelation': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductQueuesViewModel
     */
    'suspendTelkomErrors': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductQueuesViewModel
     */
    'cancelTelkomErrors': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductQueuesViewModel
     */
    'pendingTelkomActivation': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductQueuesViewModel
     */
    'newlyActivated': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductQueuesViewModel
     */
    'pendingInitialPayment': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderProductStatusesEnum = {
    New: 'New',
    Activated: 'Activated',
    PendingMigration: 'PendingMigration',
    Migrated: 'Migrated',
    Suspended: 'Suspended',
    Cancelled: 'Cancelled',
    Abandoned: 'Abandoned'
} as const;

export type OrderProductStatusesEnum = typeof OrderProductStatusesEnum[keyof typeof OrderProductStatusesEnum];


/**
 * 
 * @export
 * @interface OrderProductsDto
 */
export interface OrderProductsDto {
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'vascoDealDescription': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsDto
     */
    'productsId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'ordersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'vascoDealId': string;
    /**
     * 
     * @type {OrderProductsDtoProducts}
     * @memberof OrderProductsDto
     */
    'products': OrderProductsDtoProducts;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'msisdn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'iccid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'netIccid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'subscriberStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'activationStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'orderStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'orderState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'productOrderReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'prodcutDesctiption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'dueDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'orderProductsId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'changeSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'changeRequestStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'changeStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'previousOrderProductsId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'orderProductsDescendentsId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsDto
     */
    'previousOrderProductsProductsId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsDto
     */
    'orderProductsDescendentsProductsId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'previousOrderProductsMsisdn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'previousOrderProductsIccid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDto
     */
    'migrationDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderProductsDto
     */
    'canSuspendOrder': boolean;
    /**
     * 
     * @type {OrderProductStatusesEnum}
     * @memberof OrderProductsDto
     */
    'orderProductStatusesId': OrderProductStatusesEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OrderProductsDto
     */
    'isTopUp': boolean;
}
/**
 * 
 * @export
 * @interface OrderProductsDtoProducts
 */
export interface OrderProductsDtoProducts {
    /**
     * 
     * @type {number}
     * @memberof OrderProductsDtoProducts
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDtoProducts
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDtoProducts
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDtoProducts
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDtoProducts
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDtoProducts
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDtoProducts
     */
    'productType': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDtoProducts
     */
    'providerDealId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDtoProducts
     */
    'providerPackageName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDtoProducts
     */
    'providerDealDescription': string;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsDtoProducts
     */
    'providerCost': number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDtoProducts
     */
    'vascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDtoProducts
     */
    'vascoDealDescription': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDtoProducts
     */
    'vascoPackageDescription': string;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsDtoProducts
     */
    'vascoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsDtoProducts
     */
    'adminFee': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsDtoProducts
     */
    'agentCommission': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsDtoProducts
     */
    'dealerCommission': number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsDtoProducts
     */
    'contractPeriod'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderProductsDtoProducts
     */
    'isTopUp': boolean;
}
/**
 * 
 * @export
 * @interface OrderProductsViewModel
 */
export interface OrderProductsViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'vascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsViewModel
     */
    'productsId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'msisdn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'iccid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'netIccid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'subscriberStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'activationStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'orderStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'orderState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'productOrderReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'prodcutDesctiption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'dueDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'previousOrderProductsId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'changeSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'changeRequestStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'changeStamp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderProductsViewModel
     */
    'isTopUp': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'ordersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'migrationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsViewModel
     */
    'previousOrderProductsProductsId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'previousOrderProductsMsisdn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'previousOrderProductsIccid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModel
     */
    'orderProductsDescendentsId': string;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsViewModel
     */
    'orderProductsDescendentsProductsId': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderProductsViewModel
     */
    'canSuspendOrder': boolean;
    /**
     * 
     * @type {OrderProductsViewModelProducts}
     * @memberof OrderProductsViewModel
     */
    'products': OrderProductsViewModelProducts;
    /**
     * 
     * @type {boolean}
     * @memberof OrderProductsViewModel
     */
    'canDoActions': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderProductsViewModel
     */
    'canCancelOrder': boolean;
    /**
     * 
     * @type {OrderProductStatusesEnum}
     * @memberof OrderProductsViewModel
     */
    'orderProductStatusesId': OrderProductStatusesEnum;
}
/**
 * 
 * @export
 * @interface OrderProductsViewModelProducts
 */
export interface OrderProductsViewModelProducts {
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModelProducts
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModelProducts
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModelProducts
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModelProducts
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModelProducts
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModelProducts
     */
    'productType': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModelProducts
     */
    'providerDealId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModelProducts
     */
    'providerPackageName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModelProducts
     */
    'providerDealDescription': string;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsViewModelProducts
     */
    'providerCost': number;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModelProducts
     */
    'vascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModelProducts
     */
    'vascoDealDescription': string;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModelProducts
     */
    'vascoPackageDescription': string;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsViewModelProducts
     */
    'vascoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsViewModelProducts
     */
    'adminFee': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsViewModelProducts
     */
    'agentCommission': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsViewModelProducts
     */
    'dealerCommission': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderProductsViewModelProducts
     */
    'isTopUp': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderProductsViewModelProducts
     */
    'contractPeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderProductsViewModelProducts
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface OrderStateHistoryViewModel
 */
export interface OrderStateHistoryViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrderStateHistoryViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStateHistoryViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStateHistoryViewModel
     */
    'ordersId': string;
    /**
     * 
     * @type {OrderSubStatesEnum}
     * @memberof OrderStateHistoryViewModel
     */
    'newOrderSubStateId'?: OrderSubStatesEnum | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderSubStatesEnum = {
    NewOrder: 'NewOrder',
    PendingInitialPayment: 'PendingInitialPayment',
    PendingActivation: 'PendingActivation',
    Activated: 'Activated',
    PendingMigration: 'PendingMigration',
    Migrated: 'Migrated',
    PendingSuspension: 'PendingSuspension',
    Suspended: 'Suspended',
    PendingCancellation: 'PendingCancellation',
    Cancelled: 'Cancelled',
    Abandoned: 'Abandoned',
    PendingReActivation: 'PendingReActivation'
} as const;

export type OrderSubStatesEnum = typeof OrderSubStatesEnum[keyof typeof OrderSubStatesEnum];


/**
 * 
 * @export
 * @interface OrdersCustomersDto
 */
export interface OrdersCustomersDto {
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'idNationality': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'mobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'additionalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'suspendedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'gender': string;
    /**
     * 
     * @type {IDType}
     * @memberof OrdersCustomersDto
     */
    'idType': IDType;
    /**
     * 
     * @type {CustomerType}
     * @memberof OrdersCustomersDto
     */
    'customerType': CustomerType;
    /**
     * 
     * @type {Array<CustomerPaymentMethodsDto>}
     * @memberof OrdersCustomersDto
     */
    'customerPaymentMethods'?: Array<CustomerPaymentMethodsDto> | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersCustomersDto
     */
    'usersId'?: string | null;
    /**
     * 
     * @type {CustomersDtoAddress}
     * @memberof OrdersCustomersDto
     */
    'address': CustomersDtoAddress;
}
/**
 * 
 * @export
 * @interface OrdersDto
 */
export interface OrdersDto {
    /**
     * 
     * @type {string}
     * @memberof OrdersDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDto
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDto
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDto
     */
    'updatedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDto
     */
    'customersId': string;
    /**
     * 
     * @type {number}
     * @memberof OrdersDto
     */
    'pIMSAgentId': number;
    /**
     * 
     * @type {number}
     * @memberof OrdersDto
     */
    'pIMSDealerId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDto
     */
    'coverageCheckStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDto
     */
    'creditCheckStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDto
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrdersDto
     */
    'billingDay': number;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersDto
     */
    'acceptedTsCs'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDto
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrdersDto
     */
    'upfrontPaymentAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrdersDto
     */
    'monthlySubscriptionAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDto
     */
    'currentQueueHandler'?: string | null;
    /**
     * 
     * @type {Array<OrderProductsDto>}
     * @memberof OrdersDto
     */
    'orderProducts': Array<OrderProductsDto>;
    /**
     * 
     * @type {Array<OrderPaymentDto>}
     * @memberof OrdersDto
     */
    'payments': Array<OrderPaymentDto>;
    /**
     * 
     * @type {string}
     * @memberof OrdersDto
     */
    'customerPaymentMethodsId'?: string | null;
    /**
     * 
     * @type {OrdersDtoCustomerPaymentMethods}
     * @memberof OrdersDto
     */
    'customerPaymentMethods': OrdersDtoCustomerPaymentMethods;
    /**
     * 
     * @type {OrderSubStatesEnum}
     * @memberof OrdersDto
     */
    'orderSubStateId': OrderSubStatesEnum;
    /**
     * 
     * @type {CancelationReasonsEnum}
     * @memberof OrdersDto
     */
    'cancelationReasonsId'?: CancelationReasonsEnum | null;
    /**
     * 
     * @type {OrdersDtoCustomers}
     * @memberof OrdersDto
     */
    'customers': OrdersDtoCustomers;
    /**
     * 
     * @type {string}
     * @memberof OrdersDto
     */
    'preferredUpfrontPayementDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersDto
     */
    'isSubcription': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrdersDto
     */
    'key': number;
    /**
     * 
     * @type {number}
     * @memberof OrdersDto
     */
    'prorataAmount'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersDto
     */
    'canActivateOrder': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersDto
     */
    'canBypassTelkomSuspend': boolean;
}
/**
 * 
 * @export
 * @interface OrdersDtoCustomerPaymentMethods
 */
export interface OrdersDtoCustomerPaymentMethods {
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomerPaymentMethods
     */
    'cardId': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomerPaymentMethods
     */
    'customersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomerPaymentMethods
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomerPaymentMethods
     */
    'cardType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomerPaymentMethods
     */
    'last4Digits'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomerPaymentMethods
     */
    'bin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomerPaymentMethods
     */
    'binCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomerPaymentMethods
     */
    'holder'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomerPaymentMethods
     */
    'expiryMonth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomerPaymentMethods
     */
    'expiryYear'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomerPaymentMethods
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomerPaymentMethods
     */
    'tokenizeTransactionId'?: string | null;
}
/**
 * 
 * @export
 * @interface OrdersDtoCustomers
 */
export interface OrdersDtoCustomers {
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'idNationality': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'mobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'additionalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'suspendedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'gender': string;
    /**
     * 
     * @type {IDType}
     * @memberof OrdersDtoCustomers
     */
    'idType': IDType;
    /**
     * 
     * @type {CustomerType}
     * @memberof OrdersDtoCustomers
     */
    'customerType': CustomerType;
    /**
     * 
     * @type {Array<CustomerPaymentMethodsDto>}
     * @memberof OrdersDtoCustomers
     */
    'customerPaymentMethods'?: Array<CustomerPaymentMethodsDto> | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersDtoCustomers
     */
    'usersId'?: string | null;
    /**
     * 
     * @type {CustomersDtoAddress}
     * @memberof OrdersDtoCustomers
     */
    'address': CustomersDtoAddress;
}
/**
 * 
 * @export
 * @interface OrdersViewModel
 */
export interface OrdersViewModel {
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModel
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrdersViewModel
     */
    'pIMSAgentId': number;
    /**
     * 
     * @type {number}
     * @memberof OrdersViewModel
     */
    'pIMSDealerId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModel
     */
    'coverageCheckStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModel
     */
    'creditCheckStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModel
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersViewModel
     */
    'acceptedTsCs'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModel
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrdersViewModel
     */
    'monthlySubscriptionAmount'?: number | null;
    /**
     * 
     * @type {OrdersViewModelCustomerPaymentMethods}
     * @memberof OrdersViewModel
     */
    'customerPaymentMethods'?: OrdersViewModelCustomerPaymentMethods | null;
    /**
     * 
     * @type {OrderSubStatesEnum}
     * @memberof OrdersViewModel
     */
    'orderSubStateId': OrderSubStatesEnum;
    /**
     * 
     * @type {CancelationReasonsEnum}
     * @memberof OrdersViewModel
     */
    'cancelationReasonsId'?: CancelationReasonsEnum | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModel
     */
    'customersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModel
     */
    'currentQueueHandler'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModel
     */
    'preferredUpfrontPayementDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersViewModel
     */
    'isSubcription': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrdersViewModel
     */
    'key': number;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModel
     */
    'paymentLastScheduledDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrdersViewModel
     */
    'billingDay': number;
    /**
     * 
     * @type {Array<OrderProductsViewModel>}
     * @memberof OrdersViewModel
     */
    'orderProducts': Array<OrderProductsViewModel>;
    /**
     * 
     * @type {OrdersViewModelCustomers}
     * @memberof OrdersViewModel
     */
    'customers': OrdersViewModelCustomers;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersViewModel
     */
    'canActivateOrder': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersViewModel
     */
    'canBypassTelkomOrderMigration': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersViewModel
     */
    'canBypassTelkomSuspend': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersViewModel
     */
    'canBypassTelkomResume': boolean;
}
/**
 * 
 * @export
 * @interface OrdersViewModelCustomerPaymentMethods
 */
export interface OrdersViewModelCustomerPaymentMethods {
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'cardId': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'customersId': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'paymentMethod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'tokenizeTransactionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'cardType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'last4Digits'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'bin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'binCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'holder'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'expiryMonth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomerPaymentMethods
     */
    'expiryYear'?: string | null;
}
/**
 * 
 * @export
 * @interface OrdersViewModelCustomers
 */
export interface OrdersViewModelCustomers {
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'idNationality': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'mobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'additionalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'suspendedDate'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof OrdersViewModelCustomers
     */
    'gender': Gender;
    /**
     * 
     * @type {IDType}
     * @memberof OrdersViewModelCustomers
     */
    'idType': IDType;
    /**
     * 
     * @type {CustomerType}
     * @memberof OrdersViewModelCustomers
     */
    'customerType': CustomerType;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'usersId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersViewModelCustomers
     */
    'isAccountOtpValidated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrdersViewModelCustomers
     */
    'id': string;
    /**
     * 
     * @type {Array<CustomerPaymentMethodsViewModel>}
     * @memberof OrdersViewModelCustomers
     */
    'customerPaymentMethods': Array<CustomerPaymentMethodsViewModel>;
    /**
     * 
     * @type {CustomersUpdateDtoAddress}
     * @memberof OrdersViewModelCustomers
     */
    'address': CustomersUpdateDtoAddress;
}
/**
 * 
 * @export
 * @interface PagedResultOfAuditLogDto
 */
export interface PagedResultOfAuditLogDto {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfAuditLogDto
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfAuditLogDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfAuditLogDto
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfAuditLogDto
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<AuditLogDto>}
     * @memberof PagedResultOfAuditLogDto
     */
    'data': Array<AuditLogDto>;
}
/**
 * 
 * @export
 * @interface PagedResultOfClaimListViewModel
 */
export interface PagedResultOfClaimListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfClaimListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfClaimListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfClaimListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfClaimListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<ClaimListViewModel>}
     * @memberof PagedResultOfClaimListViewModel
     */
    'data': Array<ClaimListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfCurrentAndFutureBillingCycleViewModel
 */
export interface PagedResultOfCurrentAndFutureBillingCycleViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfCurrentAndFutureBillingCycleViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfCurrentAndFutureBillingCycleViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfCurrentAndFutureBillingCycleViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfCurrentAndFutureBillingCycleViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<CurrentAndFutureBillingCycleViewModel>}
     * @memberof PagedResultOfCurrentAndFutureBillingCycleViewModel
     */
    'data': Array<CurrentAndFutureBillingCycleViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfCustomerOrdersDto
 */
export interface PagedResultOfCustomerOrdersDto {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfCustomerOrdersDto
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfCustomerOrdersDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfCustomerOrdersDto
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfCustomerOrdersDto
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<CustomerOrdersDto>}
     * @memberof PagedResultOfCustomerOrdersDto
     */
    'data': Array<CustomerOrdersDto>;
}
/**
 * 
 * @export
 * @interface PagedResultOfCustomersDto
 */
export interface PagedResultOfCustomersDto {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfCustomersDto
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfCustomersDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfCustomersDto
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfCustomersDto
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<CustomersDto>}
     * @memberof PagedResultOfCustomersDto
     */
    'data': Array<CustomersDto>;
}
/**
 * 
 * @export
 * @interface PagedResultOfOrderInvoicesDto
 */
export interface PagedResultOfOrderInvoicesDto {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfOrderInvoicesDto
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfOrderInvoicesDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfOrderInvoicesDto
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfOrderInvoicesDto
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<OrderInvoicesDto>}
     * @memberof PagedResultOfOrderInvoicesDto
     */
    'data': Array<OrderInvoicesDto>;
}
/**
 * 
 * @export
 * @interface PagedResultOfOrderPaymentViewModel
 */
export interface PagedResultOfOrderPaymentViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfOrderPaymentViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfOrderPaymentViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfOrderPaymentViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfOrderPaymentViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<OrderPaymentViewModel>}
     * @memberof PagedResultOfOrderPaymentViewModel
     */
    'data': Array<OrderPaymentViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfOrdersViewModel
 */
export interface PagedResultOfOrdersViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfOrdersViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfOrdersViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfOrdersViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfOrdersViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<OrdersViewModel>}
     * @memberof PagedResultOfOrdersViewModel
     */
    'data': Array<OrdersViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfPaymentDueOrdersViewModel
 */
export interface PagedResultOfPaymentDueOrdersViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfPaymentDueOrdersViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfPaymentDueOrdersViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfPaymentDueOrdersViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfPaymentDueOrdersViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<PaymentDueOrdersViewModel>}
     * @memberof PagedResultOfPaymentDueOrdersViewModel
     */
    'data': Array<PaymentDueOrdersViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfPowerBiReportClaimDto
 */
export interface PagedResultOfPowerBiReportClaimDto {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfPowerBiReportClaimDto
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfPowerBiReportClaimDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfPowerBiReportClaimDto
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfPowerBiReportClaimDto
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<PowerBiReportClaimDto>}
     * @memberof PagedResultOfPowerBiReportClaimDto
     */
    'data': Array<PowerBiReportClaimDto>;
}
/**
 * 
 * @export
 * @interface PagedResultOfPowerBiReportsDto
 */
export interface PagedResultOfPowerBiReportsDto {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfPowerBiReportsDto
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfPowerBiReportsDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfPowerBiReportsDto
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfPowerBiReportsDto
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<PowerBiReportsDto>}
     * @memberof PagedResultOfPowerBiReportsDto
     */
    'data': Array<PowerBiReportsDto>;
}
/**
 * 
 * @export
 * @interface PagedResultOfProductsViewModel
 */
export interface PagedResultOfProductsViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfProductsViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfProductsViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfProductsViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfProductsViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<ProductsViewModel>}
     * @memberof PagedResultOfProductsViewModel
     */
    'data': Array<ProductsViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfRoleListViewModel
 */
export interface PagedResultOfRoleListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfRoleListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfRoleListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfRoleListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfRoleListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<RoleListViewModel>}
     * @memberof PagedResultOfRoleListViewModel
     */
    'data': Array<RoleListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfUserViewModelDto
 */
export interface PagedResultOfUserViewModelDto {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfUserViewModelDto
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfUserViewModelDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfUserViewModelDto
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfUserViewModelDto
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<UserViewModelDto>}
     * @memberof PagedResultOfUserViewModelDto
     */
    'data': Array<UserViewModelDto>;
}
/**
 * 
 * @export
 * @interface PaymentDueOrdersViewModel
 */
export interface PaymentDueOrdersViewModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentDueOrdersViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDueOrdersViewModel
     */
    'billingDay': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDueOrdersViewModel
     */
    'referenceNumber'?: string | null;
    /**
     * look at  order cost instead
     * @type {number}
     * @memberof PaymentDueOrdersViewModel
     */
    'monthlySubscriptionAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentDueOrdersViewModel
     */
    'customersId': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDueOrdersViewModel
     */
    'preferredUpfrontPayementDate': string;
    /**
     * look at  order cost instead
     * @type {number}
     * @memberof PaymentDueOrdersViewModel
     */
    'prorataAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentDueOrdersViewModel
     */
    'customersFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDueOrdersViewModel
     */
    'customersLastName': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDueOrdersViewModel
     */
    'nextBillingDay': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDueOrdersViewModel
     */
    'futureBillingDay'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentDueOrdersViewModel
     */
    'isNewCustomer': boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentDueOrdersViewModel
     */
    'paymentLastScheduledDate'?: string | null;
    /**
     * 
     * @type {Array<BillingCycleViewModel>}
     * @memberof PaymentDueOrdersViewModel
     */
    'billingCycles': Array<BillingCycleViewModel>;
    /**
     * 
     * @type {OrderSubStatesEnum}
     * @memberof PaymentDueOrdersViewModel
     */
    'orderSubStateId': OrderSubStatesEnum;
    /**
     * 
     * @type {number}
     * @memberof PaymentDueOrdersViewModel
     */
    'lastPaymentAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PaymentDueOrdersViewModel
     */
    'nextPaymentAmount': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentDueOrdersViewModel
     */
    'futurePaymentAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PaymentDueOrdersViewModel
     */
    'intialPayment': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDueOrdersViewModel
     */
    'dealDescription': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDueOrdersViewModel
     */
    'cardExpiry': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentMethodStatusEnum = {
    Valid: 'Valid',
    Invalid: 'Invalid',
    Expired: 'Expired',
    AboutToExpire: 'AboutToExpire'
} as const;

export type PaymentMethodStatusEnum = typeof PaymentMethodStatusEnum[keyof typeof PaymentMethodStatusEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentPeriod = {
    _7Days: '_7Days',
    _3Months: '_3Months',
    _6Months: '_6Months',
    _12Months: '_12Months'
} as const;

export type PaymentPeriod = typeof PaymentPeriod[keyof typeof PaymentPeriod];


/**
 * 
 * @export
 * @interface PowerBiReportClaimDto
 */
export interface PowerBiReportClaimDto {
    /**
     * 
     * @type {string}
     * @memberof PowerBiReportClaimDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PowerBiReportClaimDto
     */
    'appplicationClaimTypesId': number;
    /**
     * 
     * @type {number}
     * @memberof PowerBiReportClaimDto
     */
    'powerBiReportsId': number;
}
/**
 * 
 * @export
 * @interface PowerBiReportsDto
 */
export interface PowerBiReportsDto {
    /**
     * 
     * @type {number}
     * @memberof PowerBiReportsDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PowerBiReportsDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PowerBiReportsDto
     */
    'reportId': string;
    /**
     * 
     * @type {string}
     * @memberof PowerBiReportsDto
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProcessOrderPaymentsViewModel
 */
export interface ProcessOrderPaymentsViewModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProcessOrderPaymentsViewModel
     */
    'orderIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProcessOrderPaymentsViewModel
     */
    'billingDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProcessOrderPaymentsViewModel
     */
    'amount'?: number | null;
}
/**
 * 
 * @export
 * @interface ProductsCreateDto
 */
export interface ProductsCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ProductsCreateDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsCreateDto
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsCreateDto
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsCreateDto
     */
    'productType': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsCreateDto
     */
    'providerDealId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsCreateDto
     */
    'providerPackageName': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsCreateDto
     */
    'providerDealDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ProductsCreateDto
     */
    'providerCost': number;
    /**
     * 
     * @type {string}
     * @memberof ProductsCreateDto
     */
    'vascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsCreateDto
     */
    'vascoDealDescription': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsCreateDto
     */
    'vascoPackageDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ProductsCreateDto
     */
    'vascoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ProductsCreateDto
     */
    'adminFee': number;
    /**
     * 
     * @type {number}
     * @memberof ProductsCreateDto
     */
    'agentCommission': number;
    /**
     * 
     * @type {number}
     * @memberof ProductsCreateDto
     */
    'dealerCommission': number;
    /**
     * 
     * @type {string}
     * @memberof ProductsCreateDto
     */
    'contractPeriod'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsCreateDto
     */
    'isTopUp': boolean;
}
/**
 * 
 * @export
 * @interface ProductsDto
 */
export interface ProductsDto {
    /**
     * 
     * @type {number}
     * @memberof ProductsDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductsDto
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsDto
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductsDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsDto
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsDto
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsDto
     */
    'productType': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsDto
     */
    'providerDealId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsDto
     */
    'providerPackageName': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsDto
     */
    'providerDealDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ProductsDto
     */
    'providerCost': number;
    /**
     * 
     * @type {string}
     * @memberof ProductsDto
     */
    'vascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsDto
     */
    'vascoDealDescription': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsDto
     */
    'vascoPackageDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ProductsDto
     */
    'vascoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDto
     */
    'adminFee': number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDto
     */
    'agentCommission': number;
    /**
     * 
     * @type {number}
     * @memberof ProductsDto
     */
    'dealerCommission': number;
    /**
     * 
     * @type {string}
     * @memberof ProductsDto
     */
    'contractPeriod'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsDto
     */
    'isTopUp': boolean;
}
/**
 * 
 * @export
 * @interface ProductsListViewModel
 */
export interface ProductsListViewModel {
    /**
     * 
     * @type {number}
     * @memberof ProductsListViewModel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductsListViewModel
     */
    'vascoDealDescription': string;
}
/**
 * 
 * @export
 * @interface ProductsUpdateDto
 */
export interface ProductsUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductsUpdateDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductsUpdateDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsUpdateDto
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsUpdateDto
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsUpdateDto
     */
    'productType': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsUpdateDto
     */
    'providerDealId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsUpdateDto
     */
    'providerPackageName': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsUpdateDto
     */
    'providerDealDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ProductsUpdateDto
     */
    'providerCost': number;
    /**
     * 
     * @type {string}
     * @memberof ProductsUpdateDto
     */
    'vascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsUpdateDto
     */
    'vascoDealDescription': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsUpdateDto
     */
    'vascoPackageDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ProductsUpdateDto
     */
    'vascoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ProductsUpdateDto
     */
    'adminFee': number;
    /**
     * 
     * @type {number}
     * @memberof ProductsUpdateDto
     */
    'agentCommission': number;
    /**
     * 
     * @type {number}
     * @memberof ProductsUpdateDto
     */
    'dealerCommission': number;
    /**
     * 
     * @type {string}
     * @memberof ProductsUpdateDto
     */
    'contractPeriod'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsUpdateDto
     */
    'isTopUp': boolean;
}
/**
 * 
 * @export
 * @interface ProductsViewModel
 */
export interface ProductsViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductsViewModel
     */
    'dateTimeStamp': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsViewModel
     */
    'cancelledDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductsViewModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsViewModel
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsViewModel
     */
    'provider': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsViewModel
     */
    'productType': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsViewModel
     */
    'providerDealId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsViewModel
     */
    'providerPackageName': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsViewModel
     */
    'providerDealDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ProductsViewModel
     */
    'providerCost': number;
    /**
     * 
     * @type {string}
     * @memberof ProductsViewModel
     */
    'vascoDealId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsViewModel
     */
    'vascoDealDescription': string;
    /**
     * 
     * @type {string}
     * @memberof ProductsViewModel
     */
    'vascoPackageDescription': string;
    /**
     * 
     * @type {number}
     * @memberof ProductsViewModel
     */
    'vascoPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ProductsViewModel
     */
    'adminFee': number;
    /**
     * 
     * @type {number}
     * @memberof ProductsViewModel
     */
    'agentCommission': number;
    /**
     * 
     * @type {number}
     * @memberof ProductsViewModel
     */
    'dealerCommission': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsViewModel
     */
    'isTopUp': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductsViewModel
     */
    'contractPeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductsViewModel
     */
    'id': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const QueueStatus = {
    PendingInitialPayment: 'PendingInitialPayment',
    PendingTelkomActivation: 'PendingTelkomActivation',
    NewlyActivated: 'NewlyActivated',
    SuspendTelkomErrors: 'SuspendTelkomErrors',
    PaymentDue: 'PaymentDue',
    PaidUp: 'PaidUp',
    PendingSuspension: 'PendingSuspension',
    PendingCancelation: 'PendingCancelation',
    CancelTelkomErrors: 'CancelTelkomErrors'
} as const;

export type QueueStatus = typeof QueueStatus[keyof typeof QueueStatus];


/**
 * 
 * @export
 * @interface RatePlanDto
 */
export interface RatePlanDto {
    /**
     * 
     * @type {string}
     * @memberof RatePlanDto
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof RatePlanDto
     */
    'effectiveDate': string;
    /**
     * 
     * @type {string}
     * @memberof RatePlanDto
     */
    'expiryDate': string;
    /**
     * 
     * @type {string}
     * @memberof RatePlanDto
     */
    'createDate': string;
    /**
     * 
     * @type {string}
     * @memberof RatePlanDto
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface ReActivateOrder
 */
export interface ReActivateOrder {
    /**
     * 
     * @type {string}
     * @memberof ReActivateOrder
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface RegisterDto
 */
export interface RegisterDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ResultCodeDto
 */
export interface ResultCodeDto {
    /**
     * 
     * @type {string}
     * @memberof ResultCodeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ResultCodeDto
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface RoleListViewModel
 */
export interface RoleListViewModel {
    /**
     * 
     * @type {string}
     * @memberof RoleListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleListViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface RoleViewModel
 */
export interface RoleViewModel {
    /**
     * 
     * @type {string}
     * @memberof RoleViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleViewModel
     */
    'name': string;
    /**
     * 
     * @type {Array<ApplicationClaimType>}
     * @memberof RoleViewModel
     */
    'claims': Array<ApplicationClaimType>;
}
/**
 * 
 * @export
 * @interface SendCustomerOtpDto
 */
export interface SendCustomerOtpDto {
    /**
     * 
     * @type {MessageType}
     * @memberof SendCustomerOtpDto
     */
    'messageType': MessageType;
    /**
     * 
     * @type {string}
     * @memberof SendCustomerOtpDto
     */
    'customersId': string;
    /**
     * either mobile number or  email address
     * @type {string}
     * @memberof SendCustomerOtpDto
     */
    'contact': string;
}
/**
 * 
 * @export
 * @interface SendUpdateOtp
 */
export interface SendUpdateOtp {
    /**
     * 
     * @type {string}
     * @memberof SendUpdateOtp
     */
    'id': string;
    /**
     * 
     * @type {SendUpdateOtpDto}
     * @memberof SendUpdateOtp
     */
    'dto': SendUpdateOtpDto;
}
/**
 * 
 * @export
 * @interface SendUpdateOtpDto
 */
export interface SendUpdateOtpDto {
    /**
     * 
     * @type {MessageType}
     * @memberof SendUpdateOtpDto
     */
    'messageType': MessageType;
    /**
     * 
     * @type {string}
     * @memberof SendUpdateOtpDto
     */
    'customersId': string;
    /**
     * either mobile number or  email address
     * @type {string}
     * @memberof SendUpdateOtpDto
     */
    'contact': string;
}
/**
 * 
 * @export
 * @interface SimActionDto
 */
export interface SimActionDto {
    /**
     * 
     * @type {number}
     * @memberof SimActionDto
     */
    'productsId': number;
    /**
     * 
     * @type {string}
     * @memberof SimActionDto
     */
    'msisdn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SimActionDto
     */
    'isTopUp': boolean;
    /**
     * 
     * @type {string}
     * @memberof SimActionDto
     */
    'ordersId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SortDirection = {
    Asc: 'Asc',
    Desc: 'Desc'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];


/**
 * 
 * @export
 * @interface SubscriberInfoDto
 */
export interface SubscriberInfoDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriberInfoDto
     */
    'subscriberStatus': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInfoDto
     */
    'subscriberType': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInfoDto
     */
    'suspendedReason': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriberInfoDto
     */
    'iccid': string;
}
/**
 * 
 * @export
 * @interface TokenResultDto
 */
export interface TokenResultDto {
    /**
     * 
     * @type {string}
     * @memberof TokenResultDto
     */
    'authenticationToken': string;
    /**
     * 
     * @type {string}
     * @memberof TokenResultDto
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface UpdateBillingCycleCommand
 */
export interface UpdateBillingCycleCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingCycleCommand
     */
    'ordersId': string;
    /**
     * 
     * @type {Array<OrderCostsUpdateViewModel>}
     * @memberof UpdateBillingCycleCommand
     */
    'orderCosts'?: Array<OrderCostsUpdateViewModel> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingCycleCommand
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface UpdateCustomer
 */
export interface UpdateCustomer {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomer
     */
    'id': string;
    /**
     * 
     * @type {UpdateCustomerDto}
     * @memberof UpdateCustomer
     */
    'dto': UpdateCustomerDto;
}
/**
 * 
 * @export
 * @interface UpdateCustomerDto
 */
export interface UpdateCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'lastName': string;
    /**
     * 
     * @type {CustomersUpdateDtoAddress}
     * @memberof UpdateCustomerDto
     */
    'address': CustomersUpdateDtoAddress;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'additionalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'currentPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'newPassword'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof UpdateCustomerDto
     */
    'gender': Gender;
}
/**
 * 
 * @export
 * @interface UpdateCustomerEmail
 */
export interface UpdateCustomerEmail {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerEmail
     */
    'id': string;
    /**
     * 
     * @type {UpdateCustomerEmailDto}
     * @memberof UpdateCustomerEmail
     */
    'dto': UpdateCustomerEmailDto;
}
/**
 * 
 * @export
 * @interface UpdateCustomerEmailDto
 */
export interface UpdateCustomerEmailDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerEmailDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerEmailDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerEmailDto
     */
    'otp': string;
}
/**
 * 
 * @export
 * @interface UpdateCustomerMobileNumber
 */
export interface UpdateCustomerMobileNumber {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerMobileNumber
     */
    'id': string;
    /**
     * 
     * @type {UpdateCustomerMobileNumberDto}
     * @memberof UpdateCustomerMobileNumber
     */
    'dto': UpdateCustomerMobileNumberDto;
}
/**
 * 
 * @export
 * @interface UpdateCustomerMobileNumberDto
 */
export interface UpdateCustomerMobileNumberDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerMobileNumberDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerMobileNumberDto
     */
    'mobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerMobileNumberDto
     */
    'otp': string;
}
/**
 * 
 * @export
 * @interface UpdateCustomersAdminCommand
 */
export interface UpdateCustomersAdminCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommand
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommand
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommand
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommand
     */
    'additionalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommand
     */
    'dateOfBirth': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommand
     */
    'gender': string;
    /**
     * 
     * @type {UpdateCustomersAdminCommandAddress}
     * @memberof UpdateCustomersAdminCommand
     */
    'address': UpdateCustomersAdminCommandAddress;
}
/**
 * 
 * @export
 * @interface UpdateCustomersAdminCommandAddress
 */
export interface UpdateCustomersAdminCommandAddress {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommandAddress
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommandAddress
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommandAddress
     */
    'line3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommandAddress
     */
    'suburb': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommandAddress
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommandAddress
     */
    'province'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommandAddress
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomersAdminCommandAddress
     */
    'postalCode': string;
}
/**
 * 
 * @export
 * @interface UpdatePaymentMethod
 */
export interface UpdatePaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethod
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethod
     */
    'customerPaymentMethodId': string;
}
/**
 * 
 * @export
 * @interface UpdatePowerBiReportClaimCommand
 */
export interface UpdatePowerBiReportClaimCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdatePowerBiReportClaimCommand
     */
    'appplicationClaimTypesId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePowerBiReportClaimCommand
     */
    'powerBiReportsId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdatePowerBiReportClaimCommand
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface UpdatePowerBiReportsCommand
 */
export interface UpdatePowerBiReportsCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdatePowerBiReportsCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePowerBiReportsCommand
     */
    'reportId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePowerBiReportsCommand
     */
    'url': string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePowerBiReportsCommand
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface UpdateRoleCommand
 */
export interface UpdateRoleCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleCommand
     */
    'name': string;
    /**
     * 
     * @type {Array<ApplicationClaimType>}
     * @memberof UpdateRoleCommand
     */
    'claims': Array<ApplicationClaimType>;
}
/**
 * 
 * @export
 * @interface UpdateUsersCommand
 */
export interface UpdateUsersCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateUsersCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUsersCommand
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUsersCommand
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUsersCommand
     */
    'roleIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateUsersCommand
     */
    'password'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUsersCommand
     */
    'isEnabled'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UserViewModelDto
 */
export interface UserViewModelDto {
    /**
     * 
     * @type {string}
     * @memberof UserViewModelDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserViewModelDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UserViewModelDto
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserViewModelDto
     */
    'roleIds': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserViewModelDto
     */
    'isEnabled'?: boolean | null;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiAccountConfirmEmailPostRequest} [apiAccountConfirmEmailPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountConfirmEmailPost: async (apiAccountConfirmEmailPostRequest?: ApiAccountConfirmEmailPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/ConfirmEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiAccountConfirmEmailPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountForgotPasswordPost: async (emailAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('apiAccountForgotPasswordPost', 'emailAddress', emailAddress)
            const localVarPath = `/api/Account/ForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiAccountLoginPostRequest} [apiAccountLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLoginPost: async (apiAccountLoginPostRequest?: ApiAccountLoginPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiAccountLoginPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLogoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authenticationToken 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRefreshTokenPost: async (authenticationToken: string, refreshToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticationToken' is not null or undefined
            assertParamExists('apiAccountRefreshTokenPost', 'authenticationToken', authenticationToken)
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('apiAccountRefreshTokenPost', 'refreshToken', refreshToken)
            const localVarPath = `/api/Account/RefreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authenticationToken !== undefined) {
                localVarQueryParameter['authenticationToken'] = authenticationToken;
            }

            if (refreshToken !== undefined) {
                localVarQueryParameter['refreshToken'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiAccountRegisterPostRequest} [apiAccountRegisterPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRegisterPost: async (apiAccountRegisterPostRequest?: ApiAccountRegisterPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiAccountRegisterPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {string} passwordResetToken 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountResetForgotPasswordPost: async (emailAddress: string, passwordResetToken: string, newPassword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('apiAccountResetForgotPasswordPost', 'emailAddress', emailAddress)
            // verify required parameter 'passwordResetToken' is not null or undefined
            assertParamExists('apiAccountResetForgotPasswordPost', 'passwordResetToken', passwordResetToken)
            // verify required parameter 'newPassword' is not null or undefined
            assertParamExists('apiAccountResetForgotPasswordPost', 'newPassword', newPassword)
            const localVarPath = `/api/Account/ResetForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (passwordResetToken !== undefined) {
                localVarQueryParameter['passwordResetToken'] = passwordResetToken;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['newPassword'] = newPassword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApiAccountConfirmEmailPostRequest} [apiAccountConfirmEmailPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountConfirmEmailPost(apiAccountConfirmEmailPostRequest?: ApiAccountConfirmEmailPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountConfirmEmailPost(apiAccountConfirmEmailPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountForgotPasswordPost(emailAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountForgotPasswordPost(emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiAccountLoginPostRequest} [apiAccountLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountLoginPost(apiAccountLoginPostRequest?: ApiAccountLoginPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountLoginPost(apiAccountLoginPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountLogoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountLogoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authenticationToken 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountRefreshTokenPost(authenticationToken: string, refreshToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountRefreshTokenPost(authenticationToken, refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiAccountRegisterPostRequest} [apiAccountRegisterPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountRegisterPost(apiAccountRegisterPostRequest?: ApiAccountRegisterPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountRegisterPost(apiAccountRegisterPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {string} passwordResetToken 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountResetForgotPasswordPost(emailAddress: string, passwordResetToken: string, newPassword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountResetForgotPasswordPost(emailAddress, passwordResetToken, newPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiAccountConfirmEmailPostRequest} [apiAccountConfirmEmailPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountConfirmEmailPost(apiAccountConfirmEmailPostRequest?: ApiAccountConfirmEmailPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountConfirmEmailPost(apiAccountConfirmEmailPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountForgotPasswordPost(emailAddress: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountForgotPasswordPost(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiAccountLoginPostRequest} [apiAccountLoginPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLoginPost(apiAccountLoginPostRequest?: ApiAccountLoginPostRequest, options?: any): AxiosPromise<TokenResultDto> {
            return localVarFp.apiAccountLoginPost(apiAccountLoginPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLogoutPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authenticationToken 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRefreshTokenPost(authenticationToken: string, refreshToken: string, options?: any): AxiosPromise<TokenResultDto> {
            return localVarFp.apiAccountRefreshTokenPost(authenticationToken, refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiAccountRegisterPostRequest} [apiAccountRegisterPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRegisterPost(apiAccountRegisterPostRequest?: ApiAccountRegisterPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountRegisterPost(apiAccountRegisterPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} emailAddress 
         * @param {string} passwordResetToken 
         * @param {string} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountResetForgotPasswordPost(emailAddress: string, passwordResetToken: string, newPassword: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountResetForgotPasswordPost(emailAddress, passwordResetToken, newPassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {ApiAccountConfirmEmailPostRequest} [apiAccountConfirmEmailPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountConfirmEmailPost(apiAccountConfirmEmailPostRequest?: ApiAccountConfirmEmailPostRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountConfirmEmailPost(apiAccountConfirmEmailPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountForgotPasswordPost(emailAddress: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountForgotPasswordPost(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiAccountLoginPostRequest} [apiAccountLoginPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountLoginPost(apiAccountLoginPostRequest?: ApiAccountLoginPostRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountLoginPost(apiAccountLoginPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountLogoutPost(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authenticationToken 
     * @param {string} refreshToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountRefreshTokenPost(authenticationToken: string, refreshToken: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountRefreshTokenPost(authenticationToken, refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiAccountRegisterPostRequest} [apiAccountRegisterPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountRegisterPost(apiAccountRegisterPostRequest?: ApiAccountRegisterPostRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountRegisterPost(apiAccountRegisterPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} emailAddress 
     * @param {string} passwordResetToken 
     * @param {string} newPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountResetForgotPasswordPost(emailAddress: string, passwordResetToken: string, newPassword: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountResetForgotPasswordPost(emailAddress, passwordResetToken, newPassword, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuditLogsApi - axios parameter creator
 * @export
 */
export const AuditLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {AuditTypes} [auditLogTypesId] 
         * @param {string} [searchText] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditLogsGet: async (pageNo: number, pageSize: number, auditLogTypesId?: AuditTypes, searchText?: string, sortDirection?: SortDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNo' is not null or undefined
            assertParamExists('apiAuditLogsGet', 'pageNo', pageNo)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiAuditLogsGet', 'pageSize', pageSize)
            const localVarPath = `/api/audit-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (auditLogTypesId !== undefined) {
                localVarQueryParameter['auditLogTypesId'] = auditLogTypesId;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditLogsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAuditLogsIdGet', 'id', id)
            const localVarPath = `/api/audit-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {string} customerId 
         * @param {AuditTypes} [auditLogTypesId] 
         * @param {string} [searchText] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersCustomerIdAuditLogsGet: async (pageNo: number, pageSize: number, customerId: string, auditLogTypesId?: AuditTypes, searchText?: string, sortDirection?: SortDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNo' is not null or undefined
            assertParamExists('apiCustomersCustomerIdAuditLogsGet', 'pageNo', pageNo)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiCustomersCustomerIdAuditLogsGet', 'pageSize', pageSize)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiCustomersCustomerIdAuditLogsGet', 'customerId', customerId)
            const localVarPath = `/api/customers/{customerId}/audit-logs`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (auditLogTypesId !== undefined) {
                localVarQueryParameter['auditLogTypesId'] = auditLogTypesId;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {string} ordersId 
         * @param {AuditTypes} [auditLogTypesId] 
         * @param {string} [searchText] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersOrdersIdAuditLogsGet: async (pageNo: number, pageSize: number, ordersId: string, auditLogTypesId?: AuditTypes, searchText?: string, sortDirection?: SortDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNo' is not null or undefined
            assertParamExists('apiOrdersOrdersIdAuditLogsGet', 'pageNo', pageNo)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiOrdersOrdersIdAuditLogsGet', 'pageSize', pageSize)
            // verify required parameter 'ordersId' is not null or undefined
            assertParamExists('apiOrdersOrdersIdAuditLogsGet', 'ordersId', ordersId)
            const localVarPath = `/api/orders/{ordersId}/audit-logs`
                .replace(`{${"ordersId"}}`, encodeURIComponent(String(ordersId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (auditLogTypesId !== undefined) {
                localVarQueryParameter['auditLogTypesId'] = auditLogTypesId;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditLogsApi - functional programming interface
 * @export
 */
export const AuditLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {AuditTypes} [auditLogTypesId] 
         * @param {string} [searchText] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditLogsGet(pageNo: number, pageSize: number, auditLogTypesId?: AuditTypes, searchText?: string, sortDirection?: SortDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfAuditLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditLogsGet(pageNo, pageSize, auditLogTypesId, searchText, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditLogsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditLogsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {string} customerId 
         * @param {AuditTypes} [auditLogTypesId] 
         * @param {string} [searchText] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersCustomerIdAuditLogsGet(pageNo: number, pageSize: number, customerId: string, auditLogTypesId?: AuditTypes, searchText?: string, sortDirection?: SortDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfAuditLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersCustomerIdAuditLogsGet(pageNo, pageSize, customerId, auditLogTypesId, searchText, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {string} ordersId 
         * @param {AuditTypes} [auditLogTypesId] 
         * @param {string} [searchText] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersOrdersIdAuditLogsGet(pageNo: number, pageSize: number, ordersId: string, auditLogTypesId?: AuditTypes, searchText?: string, sortDirection?: SortDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfAuditLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersOrdersIdAuditLogsGet(pageNo, pageSize, ordersId, auditLogTypesId, searchText, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditLogsApi - factory interface
 * @export
 */
export const AuditLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditLogsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {AuditTypes} [auditLogTypesId] 
         * @param {string} [searchText] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditLogsGet(pageNo: number, pageSize: number, auditLogTypesId?: AuditTypes, searchText?: string, sortDirection?: SortDirection, options?: any): AxiosPromise<PagedResultOfAuditLogDto> {
            return localVarFp.apiAuditLogsGet(pageNo, pageSize, auditLogTypesId, searchText, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditLogsIdGet(id: string, options?: any): AxiosPromise<AuditLogDto> {
            return localVarFp.apiAuditLogsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {string} customerId 
         * @param {AuditTypes} [auditLogTypesId] 
         * @param {string} [searchText] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersCustomerIdAuditLogsGet(pageNo: number, pageSize: number, customerId: string, auditLogTypesId?: AuditTypes, searchText?: string, sortDirection?: SortDirection, options?: any): AxiosPromise<PagedResultOfAuditLogDto> {
            return localVarFp.apiCustomersCustomerIdAuditLogsGet(pageNo, pageSize, customerId, auditLogTypesId, searchText, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {string} ordersId 
         * @param {AuditTypes} [auditLogTypesId] 
         * @param {string} [searchText] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersOrdersIdAuditLogsGet(pageNo: number, pageSize: number, ordersId: string, auditLogTypesId?: AuditTypes, searchText?: string, sortDirection?: SortDirection, options?: any): AxiosPromise<PagedResultOfAuditLogDto> {
            return localVarFp.apiOrdersOrdersIdAuditLogsGet(pageNo, pageSize, ordersId, auditLogTypesId, searchText, sortDirection, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditLogsApi - object-oriented interface
 * @export
 * @class AuditLogsApi
 * @extends {BaseAPI}
 */
export class AuditLogsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} pageNo 
     * @param {number} pageSize 
     * @param {AuditTypes} [auditLogTypesId] 
     * @param {string} [searchText] 
     * @param {SortDirection} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogsApi
     */
    public apiAuditLogsGet(pageNo: number, pageSize: number, auditLogTypesId?: AuditTypes, searchText?: string, sortDirection?: SortDirection, options?: AxiosRequestConfig) {
        return AuditLogsApiFp(this.configuration).apiAuditLogsGet(pageNo, pageSize, auditLogTypesId, searchText, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogsApi
     */
    public apiAuditLogsIdGet(id: string, options?: AxiosRequestConfig) {
        return AuditLogsApiFp(this.configuration).apiAuditLogsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} pageNo 
     * @param {number} pageSize 
     * @param {string} customerId 
     * @param {AuditTypes} [auditLogTypesId] 
     * @param {string} [searchText] 
     * @param {SortDirection} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogsApi
     */
    public apiCustomersCustomerIdAuditLogsGet(pageNo: number, pageSize: number, customerId: string, auditLogTypesId?: AuditTypes, searchText?: string, sortDirection?: SortDirection, options?: AxiosRequestConfig) {
        return AuditLogsApiFp(this.configuration).apiCustomersCustomerIdAuditLogsGet(pageNo, pageSize, customerId, auditLogTypesId, searchText, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} pageNo 
     * @param {number} pageSize 
     * @param {string} ordersId 
     * @param {AuditTypes} [auditLogTypesId] 
     * @param {string} [searchText] 
     * @param {SortDirection} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogsApi
     */
    public apiOrdersOrdersIdAuditLogsGet(pageNo: number, pageSize: number, ordersId: string, auditLogTypesId?: AuditTypes, searchText?: string, sortDirection?: SortDirection, options?: AxiosRequestConfig) {
        return AuditLogsApiFp(this.configuration).apiOrdersOrdersIdAuditLogsGet(pageNo, pageSize, ordersId, auditLogTypesId, searchText, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BillingCyclesApi - axios parameter creator
 * @export
 */
export const BillingCyclesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {number} [billingDay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBillingCycleGet: async (pageNo: number, pageSize: number, billingDay?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNo' is not null or undefined
            assertParamExists('apiBillingCycleGet', 'pageNo', pageNo)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiBillingCycleGet', 'pageSize', pageSize)
            const localVarPath = `/api/billing-cycle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (billingDay !== undefined) {
                localVarQueryParameter['billingDay'] = billingDay;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBillingCycleIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBillingCycleIdGet', 'id', id)
            const localVarPath = `/api/billing-cycle/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiBillingCycleIdGetRequest} [apiBillingCycleIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBillingCycleIdPut: async (id: string, apiBillingCycleIdGetRequest?: ApiBillingCycleIdGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBillingCycleIdPut', 'id', id)
            const localVarPath = `/api/billing-cycle/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiBillingCycleIdGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ApiBillingCycleGetRequest} [apiBillingCycleGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBillingCyclePost: async (apiBillingCycleGetRequest?: ApiBillingCycleGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing-cycle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiBillingCycleGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingCyclesApi - functional programming interface
 * @export
 */
export const BillingCyclesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingCyclesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {number} [billingDay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBillingCycleGet(pageNo: number, pageSize: number, billingDay?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfCurrentAndFutureBillingCycleViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBillingCycleGet(pageNo, pageSize, billingDay, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBillingCycleIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingCycleViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBillingCycleIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiBillingCycleIdGetRequest} [apiBillingCycleIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBillingCycleIdPut(id: string, apiBillingCycleIdGetRequest?: ApiBillingCycleIdGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBillingCycleIdPut(id, apiBillingCycleIdGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ApiBillingCycleGetRequest} [apiBillingCycleGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBillingCyclePost(apiBillingCycleGetRequest?: ApiBillingCycleGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBillingCyclePost(apiBillingCycleGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingCyclesApi - factory interface
 * @export
 */
export const BillingCyclesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingCyclesApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {number} [billingDay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBillingCycleGet(pageNo: number, pageSize: number, billingDay?: number, options?: any): AxiosPromise<PagedResultOfCurrentAndFutureBillingCycleViewModel> {
            return localVarFp.apiBillingCycleGet(pageNo, pageSize, billingDay, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBillingCycleIdGet(id: string, options?: any): AxiosPromise<BillingCycleViewModel> {
            return localVarFp.apiBillingCycleIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiBillingCycleIdGetRequest} [apiBillingCycleIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBillingCycleIdPut(id: string, apiBillingCycleIdGetRequest?: ApiBillingCycleIdGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiBillingCycleIdPut(id, apiBillingCycleIdGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ApiBillingCycleGetRequest} [apiBillingCycleGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBillingCyclePost(apiBillingCycleGetRequest?: ApiBillingCycleGetRequest, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiBillingCyclePost(apiBillingCycleGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingCyclesApi - object-oriented interface
 * @export
 * @class BillingCyclesApi
 * @extends {BaseAPI}
 */
export class BillingCyclesApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} pageNo 
     * @param {number} pageSize 
     * @param {number} [billingDay] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingCyclesApi
     */
    public apiBillingCycleGet(pageNo: number, pageSize: number, billingDay?: number, options?: AxiosRequestConfig) {
        return BillingCyclesApiFp(this.configuration).apiBillingCycleGet(pageNo, pageSize, billingDay, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingCyclesApi
     */
    public apiBillingCycleIdGet(id: string, options?: AxiosRequestConfig) {
        return BillingCyclesApiFp(this.configuration).apiBillingCycleIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {ApiBillingCycleIdGetRequest} [apiBillingCycleIdGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingCyclesApi
     */
    public apiBillingCycleIdPut(id: string, apiBillingCycleIdGetRequest?: ApiBillingCycleIdGetRequest, options?: AxiosRequestConfig) {
        return BillingCyclesApiFp(this.configuration).apiBillingCycleIdPut(id, apiBillingCycleIdGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ApiBillingCycleGetRequest} [apiBillingCycleGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingCyclesApi
     */
    public apiBillingCyclePost(apiBillingCycleGetRequest?: ApiBillingCycleGetRequest, options?: AxiosRequestConfig) {
        return BillingCyclesApiFp(this.configuration).apiBillingCyclePost(apiBillingCycleGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BillingDaysApi - axios parameter creator
 * @export
 */
export const BillingDaysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBillingDayGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing-day`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingDaysApi - functional programming interface
 * @export
 */
export const BillingDaysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingDaysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBillingDayGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingDaysListViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBillingDayGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingDaysApi - factory interface
 * @export
 */
export const BillingDaysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingDaysApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBillingDayGet(options?: any): AxiosPromise<Array<BillingDaysListViewModel>> {
            return localVarFp.apiBillingDayGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingDaysApi - object-oriented interface
 * @export
 * @class BillingDaysApi
 * @extends {BaseAPI}
 */
export class BillingDaysApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingDaysApi
     */
    public apiBillingDayGet(options?: AxiosRequestConfig) {
        return BillingDaysApiFp(this.configuration).apiBillingDayGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CancelationReasonsApi - axios parameter creator
 * @export
 */
export const CancelationReasonsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary TODO fix routing to be \"cancelation-reason\"
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCancelationreasonsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cancelationreasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TODO fix routing to be \"cancelation-reason\"
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCancelationreasonsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCancelationreasonsIdGet', 'id', id)
            const localVarPath = `/api/cancelationreasons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CancelationReasonsApi - functional programming interface
 * @export
 */
export const CancelationReasonsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CancelationReasonsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary TODO fix routing to be \"cancelation-reason\"
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCancelationreasonsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CancelationReasonsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCancelationreasonsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary TODO fix routing to be \"cancelation-reason\"
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCancelationreasonsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelationReasonsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCancelationreasonsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CancelationReasonsApi - factory interface
 * @export
 */
export const CancelationReasonsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CancelationReasonsApiFp(configuration)
    return {
        /**
         * 
         * @summary TODO fix routing to be \"cancelation-reason\"
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCancelationreasonsGet(options?: any): AxiosPromise<Array<CancelationReasonsDto>> {
            return localVarFp.apiCancelationreasonsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TODO fix routing to be \"cancelation-reason\"
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCancelationreasonsIdGet(id: number, options?: any): AxiosPromise<CancelationReasonsDto> {
            return localVarFp.apiCancelationreasonsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CancelationReasonsApi - object-oriented interface
 * @export
 * @class CancelationReasonsApi
 * @extends {BaseAPI}
 */
export class CancelationReasonsApi extends BaseAPI {
    /**
     * 
     * @summary TODO fix routing to be \"cancelation-reason\"
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CancelationReasonsApi
     */
    public apiCancelationreasonsGet(options?: AxiosRequestConfig) {
        return CancelationReasonsApiFp(this.configuration).apiCancelationreasonsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TODO fix routing to be \"cancelation-reason\"
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CancelationReasonsApi
     */
    public apiCancelationreasonsIdGet(id: number, options?: AxiosRequestConfig) {
        return CancelationReasonsApiFp(this.configuration).apiCancelationreasonsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerOtpsApi - axios parameter creator
 * @export
 */
export const CustomerOtpsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersIdSendOtpPostRequest} [apiCustomersIdSendOtpPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdSendOtpPost: async (id: string, apiCustomersIdSendOtpPostRequest?: ApiCustomersIdSendOtpPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomersIdSendOtpPost', 'id', id)
            const localVarPath = `/api/customers/{id}/send-otp`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiCustomersIdSendOtpPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerOtpsApi - functional programming interface
 * @export
 */
export const CustomerOtpsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerOtpsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersIdSendOtpPostRequest} [apiCustomersIdSendOtpPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersIdSendOtpPost(id: string, apiCustomersIdSendOtpPostRequest?: ApiCustomersIdSendOtpPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersIdSendOtpPost(id, apiCustomersIdSendOtpPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerOtpsApi - factory interface
 * @export
 */
export const CustomerOtpsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerOtpsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersIdSendOtpPostRequest} [apiCustomersIdSendOtpPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdSendOtpPost(id: string, apiCustomersIdSendOtpPostRequest?: ApiCustomersIdSendOtpPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomersIdSendOtpPost(id, apiCustomersIdSendOtpPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerOtpsApi - object-oriented interface
 * @export
 * @class CustomerOtpsApi
 * @extends {BaseAPI}
 */
export class CustomerOtpsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {ApiCustomersIdSendOtpPostRequest} [apiCustomersIdSendOtpPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerOtpsApi
     */
    public apiCustomersIdSendOtpPost(id: string, apiCustomersIdSendOtpPostRequest?: ApiCustomersIdSendOtpPostRequest, options?: AxiosRequestConfig) {
        return CustomerOtpsApiFp(this.configuration).apiCustomersIdSendOtpPost(id, apiCustomersIdSendOtpPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerPaymentMethodsApi - axios parameter creator
 * @export
 */
export const CustomerPaymentMethodsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerPaymentMethodGet: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiCustomerPaymentMethodGet', 'customerId', customerId)
            const localVarPath = `/api/customer-payment-method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerPaymentMethodIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomerPaymentMethodIdDelete', 'id', id)
            const localVarPath = `/api/customer-payment-method/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerPaymentMethodIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomerPaymentMethodIdGet', 'id', id)
            const localVarPath = `/api/customer-payment-method/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ApiCustomerPaymentMethodGetRequest} [apiCustomerPaymentMethodGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerPaymentMethodPost: async (apiCustomerPaymentMethodGetRequest?: ApiCustomerPaymentMethodGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer-payment-method`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiCustomerPaymentMethodGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerPaymentMethodsApi - functional programming interface
 * @export
 */
export const CustomerPaymentMethodsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerPaymentMethodsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerPaymentMethodGet(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerPaymentMethodDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerPaymentMethodGet(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerPaymentMethodIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerPaymentMethodIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerPaymentMethodIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerPaymentMethodDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerPaymentMethodIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ApiCustomerPaymentMethodGetRequest} [apiCustomerPaymentMethodGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerPaymentMethodPost(apiCustomerPaymentMethodGetRequest?: ApiCustomerPaymentMethodGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerPaymentMethodPost(apiCustomerPaymentMethodGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerPaymentMethodsApi - factory interface
 * @export
 */
export const CustomerPaymentMethodsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerPaymentMethodsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerPaymentMethodGet(customerId: string, options?: any): AxiosPromise<Array<CustomerPaymentMethodDto>> {
            return localVarFp.apiCustomerPaymentMethodGet(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerPaymentMethodIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerPaymentMethodIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerPaymentMethodIdGet(id: string, options?: any): AxiosPromise<CustomerPaymentMethodDto> {
            return localVarFp.apiCustomerPaymentMethodIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ApiCustomerPaymentMethodGetRequest} [apiCustomerPaymentMethodGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerPaymentMethodPost(apiCustomerPaymentMethodGetRequest?: ApiCustomerPaymentMethodGetRequest, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiCustomerPaymentMethodPost(apiCustomerPaymentMethodGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerPaymentMethodsApi - object-oriented interface
 * @export
 * @class CustomerPaymentMethodsApi
 * @extends {BaseAPI}
 */
export class CustomerPaymentMethodsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerPaymentMethodsApi
     */
    public apiCustomerPaymentMethodGet(customerId: string, options?: AxiosRequestConfig) {
        return CustomerPaymentMethodsApiFp(this.configuration).apiCustomerPaymentMethodGet(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerPaymentMethodsApi
     */
    public apiCustomerPaymentMethodIdDelete(id: string, options?: AxiosRequestConfig) {
        return CustomerPaymentMethodsApiFp(this.configuration).apiCustomerPaymentMethodIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerPaymentMethodsApi
     */
    public apiCustomerPaymentMethodIdGet(id: string, options?: AxiosRequestConfig) {
        return CustomerPaymentMethodsApiFp(this.configuration).apiCustomerPaymentMethodIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ApiCustomerPaymentMethodGetRequest} [apiCustomerPaymentMethodGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerPaymentMethodsApi
     */
    public apiCustomerPaymentMethodPost(apiCustomerPaymentMethodGetRequest?: ApiCustomerPaymentMethodGetRequest, options?: AxiosRequestConfig) {
        return CustomerPaymentMethodsApiFp(this.configuration).apiCustomerPaymentMethodPost(apiCustomerPaymentMethodGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAdminGet: async (pageNumber: number, pageSize: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiCustomersAdminGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiCustomersAdminGet', 'pageSize', pageSize)
            const localVarPath = `/api/customers/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAdminIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomersAdminIdGet', 'id', id)
            const localVarPath = `/api/customers/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAdminIdOrdersGet: async (id: string, pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomersAdminIdOrdersGet', 'id', id)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiCustomersAdminIdOrdersGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiCustomersAdminIdOrdersGet', 'pageSize', pageSize)
            const localVarPath = `/api/customers/admin/{id}/orders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersAdminIdGetRequest} [apiCustomersAdminIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAdminIdPut: async (id: string, apiCustomersAdminIdGetRequest?: ApiCustomersAdminIdGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomersAdminIdPut', 'id', id)
            const localVarPath = `/api/customers/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiCustomersAdminIdGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersIdEmailPutRequest} [apiCustomersIdEmailPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdEmailPut: async (id: string, apiCustomersIdEmailPutRequest?: ApiCustomersIdEmailPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomersIdEmailPut', 'id', id)
            const localVarPath = `/api/customers/{id}/email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiCustomersIdEmailPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomersIdGet', 'id', id)
            const localVarPath = `/api/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersIdMobilenumberPutRequest} [apiCustomersIdMobilenumberPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdMobilenumberPut: async (id: string, apiCustomersIdMobilenumberPutRequest?: ApiCustomersIdMobilenumberPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomersIdMobilenumberPut', 'id', id)
            const localVarPath = `/api/customers/{id}/mobilenumber`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiCustomersIdMobilenumberPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdOrdersGet: async (pageNumber: number, pageSize: number, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiCustomersIdOrdersGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiCustomersIdOrdersGet', 'pageSize', pageSize)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomersIdOrdersGet', 'id', id)
            const localVarPath = `/api/customers/{id}/Orders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersIdGetRequest} [apiCustomersIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdPut: async (id: string, apiCustomersIdGetRequest?: ApiCustomersIdGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomersIdPut', 'id', id)
            const localVarPath = `/api/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiCustomersIdGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mobileNumber 
         * @param {string} orderReferenceNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersRegistrationMobileNumberGet: async (mobileNumber: string, orderReferenceNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mobileNumber' is not null or undefined
            assertParamExists('apiCustomersRegistrationMobileNumberGet', 'mobileNumber', mobileNumber)
            // verify required parameter 'orderReferenceNumber' is not null or undefined
            assertParamExists('apiCustomersRegistrationMobileNumberGet', 'orderReferenceNumber', orderReferenceNumber)
            const localVarPath = `/api/customers/registration/{mobileNumber}`
                .replace(`{${"mobileNumber"}}`, encodeURIComponent(String(mobileNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderReferenceNumber !== undefined) {
                localVarQueryParameter['orderReferenceNumber'] = orderReferenceNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ApiCustomersRegistrationPostRequest} [apiCustomersRegistrationPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersRegistrationPost: async (apiCustomersRegistrationPostRequest?: ApiCustomersRegistrationPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customers/registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiCustomersRegistrationPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} otp 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersValidateOtpGet: async (otp: string, identifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'otp' is not null or undefined
            assertParamExists('apiCustomersValidateOtpGet', 'otp', otp)
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('apiCustomersValidateOtpGet', 'identifier', identifier)
            const localVarPath = `/api/customers/validate-otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (otp !== undefined) {
                localVarQueryParameter['otp'] = otp;
            }

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersAdminGet(pageNumber: number, pageSize: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfCustomersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersAdminGet(pageNumber, pageSize, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersAdminIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersAdminIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersAdminIdOrdersGet(id: string, pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfOrdersViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersAdminIdOrdersGet(id, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersAdminIdGetRequest} [apiCustomersAdminIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersAdminIdPut(id: string, apiCustomersAdminIdGetRequest?: ApiCustomersAdminIdGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersAdminIdPut(id, apiCustomersAdminIdGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersIdEmailPutRequest} [apiCustomersIdEmailPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersIdEmailPut(id: string, apiCustomersIdEmailPutRequest?: ApiCustomersIdEmailPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersIdEmailPut(id, apiCustomersIdEmailPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersIdMobilenumberPutRequest} [apiCustomersIdMobilenumberPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersIdMobilenumberPut(id: string, apiCustomersIdMobilenumberPutRequest?: ApiCustomersIdMobilenumberPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersIdMobilenumberPut(id, apiCustomersIdMobilenumberPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersIdOrdersGet(pageNumber: number, pageSize: number, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfCustomerOrdersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersIdOrdersGet(pageNumber, pageSize, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersIdGetRequest} [apiCustomersIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersIdPut(id: string, apiCustomersIdGetRequest?: ApiCustomersIdGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersIdPut(id, apiCustomersIdGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mobileNumber 
         * @param {string} orderReferenceNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersRegistrationMobileNumberGet(mobileNumber: string, orderReferenceNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OkResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersRegistrationMobileNumberGet(mobileNumber, orderReferenceNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ApiCustomersRegistrationPostRequest} [apiCustomersRegistrationPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersRegistrationPost(apiCustomersRegistrationPostRequest?: ApiCustomersRegistrationPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersRegistrationPost(apiCustomersRegistrationPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} otp 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersValidateOtpGet(otp: string, identifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersValidateOtpGet(otp, identifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAdminGet(pageNumber: number, pageSize: number, filter?: string, options?: any): AxiosPromise<PagedResultOfCustomersDto> {
            return localVarFp.apiCustomersAdminGet(pageNumber, pageSize, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAdminIdGet(id: string, options?: any): AxiosPromise<CustomersDto> {
            return localVarFp.apiCustomersAdminIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAdminIdOrdersGet(id: string, pageNumber: number, pageSize: number, options?: any): AxiosPromise<PagedResultOfOrdersViewModel> {
            return localVarFp.apiCustomersAdminIdOrdersGet(id, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersAdminIdGetRequest} [apiCustomersAdminIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersAdminIdPut(id: string, apiCustomersAdminIdGetRequest?: ApiCustomersAdminIdGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomersAdminIdPut(id, apiCustomersAdminIdGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersIdEmailPutRequest} [apiCustomersIdEmailPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdEmailPut(id: string, apiCustomersIdEmailPutRequest?: ApiCustomersIdEmailPutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomersIdEmailPut(id, apiCustomersIdEmailPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdGet(id: string, options?: any): AxiosPromise<CustomersDto> {
            return localVarFp.apiCustomersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersIdMobilenumberPutRequest} [apiCustomersIdMobilenumberPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdMobilenumberPut(id: string, apiCustomersIdMobilenumberPutRequest?: ApiCustomersIdMobilenumberPutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomersIdMobilenumberPut(id, apiCustomersIdMobilenumberPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdOrdersGet(pageNumber: number, pageSize: number, id: string, options?: any): AxiosPromise<PagedResultOfCustomerOrdersDto> {
            return localVarFp.apiCustomersIdOrdersGet(pageNumber, pageSize, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiCustomersIdGetRequest} [apiCustomersIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdPut(id: string, apiCustomersIdGetRequest?: ApiCustomersIdGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomersIdPut(id, apiCustomersIdGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mobileNumber 
         * @param {string} orderReferenceNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersRegistrationMobileNumberGet(mobileNumber: string, orderReferenceNumber: string, options?: any): AxiosPromise<OkResult> {
            return localVarFp.apiCustomersRegistrationMobileNumberGet(mobileNumber, orderReferenceNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ApiCustomersRegistrationPostRequest} [apiCustomersRegistrationPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersRegistrationPost(apiCustomersRegistrationPostRequest?: ApiCustomersRegistrationPostRequest, options?: any): AxiosPromise<JsonResponseOfString> {
            return localVarFp.apiCustomersRegistrationPost(apiCustomersRegistrationPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} otp 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersValidateOtpGet(otp: string, identifier: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiCustomersValidateOtpGet(otp, identifier, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersAdminGet(pageNumber: number, pageSize: number, filter?: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiCustomersAdminGet(pageNumber, pageSize, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersAdminIdGet(id: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiCustomersAdminIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersAdminIdOrdersGet(id: string, pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiCustomersAdminIdOrdersGet(id, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {ApiCustomersAdminIdGetRequest} [apiCustomersAdminIdGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersAdminIdPut(id: string, apiCustomersAdminIdGetRequest?: ApiCustomersAdminIdGetRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiCustomersAdminIdPut(id, apiCustomersAdminIdGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {ApiCustomersIdEmailPutRequest} [apiCustomersIdEmailPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersIdEmailPut(id: string, apiCustomersIdEmailPutRequest?: ApiCustomersIdEmailPutRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiCustomersIdEmailPut(id, apiCustomersIdEmailPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersIdGet(id: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiCustomersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {ApiCustomersIdMobilenumberPutRequest} [apiCustomersIdMobilenumberPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersIdMobilenumberPut(id: string, apiCustomersIdMobilenumberPutRequest?: ApiCustomersIdMobilenumberPutRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiCustomersIdMobilenumberPut(id, apiCustomersIdMobilenumberPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersIdOrdersGet(pageNumber: number, pageSize: number, id: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiCustomersIdOrdersGet(pageNumber, pageSize, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {ApiCustomersIdGetRequest} [apiCustomersIdGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersIdPut(id: string, apiCustomersIdGetRequest?: ApiCustomersIdGetRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiCustomersIdPut(id, apiCustomersIdGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mobileNumber 
     * @param {string} orderReferenceNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersRegistrationMobileNumberGet(mobileNumber: string, orderReferenceNumber: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiCustomersRegistrationMobileNumberGet(mobileNumber, orderReferenceNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ApiCustomersRegistrationPostRequest} [apiCustomersRegistrationPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersRegistrationPost(apiCustomersRegistrationPostRequest?: ApiCustomersRegistrationPostRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiCustomersRegistrationPost(apiCustomersRegistrationPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} otp 
     * @param {string} identifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public apiCustomersValidateOtpGet(otp: string, identifier: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).apiCustomersValidateOtpGet(otp, identifier, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomersServicesApi - axios parameter creator
 * @export
 */
export const CustomersServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdInvoicesGet: async (pageNumber: number, pageSize: number, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiCustomersIdInvoicesGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiCustomersIdInvoicesGet', 'pageSize', pageSize)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomersIdInvoicesGet', 'id', id)
            const localVarPath = `/api/customers/{id}/invoices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdInvoicesInvoiceIdGet: async (id: string, invoiceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCustomersIdInvoicesInvoiceIdGet', 'id', id)
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiCustomersIdInvoicesInvoiceIdGet', 'invoiceId', invoiceId)
            const localVarPath = `/api/customers/{id}/invoices/{invoiceId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersServicesApi - functional programming interface
 * @export
 */
export const CustomersServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersIdInvoicesGet(pageNumber: number, pageSize: number, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfOrderInvoicesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersIdInvoicesGet(pageNumber, pageSize, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomersIdInvoicesInvoiceIdGet(id: string, invoiceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderInvoiceHtmlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomersIdInvoicesInvoiceIdGet(id, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomersServicesApi - factory interface
 * @export
 */
export const CustomersServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersServicesApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdInvoicesGet(pageNumber: number, pageSize: number, id: string, options?: any): AxiosPromise<PagedResultOfOrderInvoicesDto> {
            return localVarFp.apiCustomersIdInvoicesGet(pageNumber, pageSize, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomersIdInvoicesInvoiceIdGet(id: string, invoiceId: string, options?: any): AxiosPromise<OrderInvoiceHtmlDto> {
            return localVarFp.apiCustomersIdInvoicesInvoiceIdGet(id, invoiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersServicesApi - object-oriented interface
 * @export
 * @class CustomersServicesApi
 * @extends {BaseAPI}
 */
export class CustomersServicesApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersServicesApi
     */
    public apiCustomersIdInvoicesGet(pageNumber: number, pageSize: number, id: string, options?: AxiosRequestConfig) {
        return CustomersServicesApiFp(this.configuration).apiCustomersIdInvoicesGet(pageNumber, pageSize, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersServicesApi
     */
    public apiCustomersIdInvoicesInvoiceIdGet(id: string, invoiceId: string, options?: AxiosRequestConfig) {
        return CustomersServicesApiFp(this.configuration).apiCustomersIdInvoicesInvoiceIdGet(id, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesRelativePathGet: async (relativePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesRelativePathGet', 'relativePath', relativePath)
            const localVarPath = `/files/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesViewRelativePathGet: async (relativePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesViewRelativePathGet', 'relativePath', relativePath)
            const localVarPath = `/files/view/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesWidthHeightRelativePathGet: async (relativePath: string, width: number, height: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'relativePath', relativePath)
            // verify required parameter 'width' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'width', width)
            // verify required parameter 'height' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'height', height)
            const localVarPath = `/files/{width}/{height}/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)))
                .replace(`{${"width"}}`, encodeURIComponent(String(width)))
                .replace(`{${"height"}}`, encodeURIComponent(String(height)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesPost(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilePath>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesRelativePathGet(relativePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesRelativePathGet(relativePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesViewRelativePathGet(relativePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesViewRelativePathGet(relativePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesWidthHeightRelativePathGet(relativePath, width, height, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPost(file?: File, options?: any): AxiosPromise<FilePath> {
            return localVarFp.filesPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesRelativePathGet(relativePath: string, options?: any): AxiosPromise<string> {
            return localVarFp.filesRelativePathGet(relativePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesViewRelativePathGet(relativePath: string, options?: any): AxiosPromise<string> {
            return localVarFp.filesViewRelativePathGet(relativePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: any): AxiosPromise<string> {
            return localVarFp.filesWidthHeightRelativePathGet(relativePath, width, height, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesPost(file?: File, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesRelativePathGet(relativePath: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesRelativePathGet(relativePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesViewRelativePathGet(relativePath: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesViewRelativePathGet(relativePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {number} width 
     * @param {number} height 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesWidthHeightRelativePathGet(relativePath, width, height, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImportsApi - axios parameter creator
 * @export
 */
export const ImportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImportIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImportIdGet', 'id', id)
            const localVarPath = `/api/import/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImportsApi - functional programming interface
 * @export
 */
export const ImportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImportIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImportIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImportsApi - factory interface
 * @export
 */
export const ImportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImportsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImportIdGet(id: string, options?: any): AxiosPromise<ImportDto> {
            return localVarFp.apiImportIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImportsApi - object-oriented interface
 * @export
 * @class ImportsApi
 * @extends {BaseAPI}
 */
export class ImportsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportsApi
     */
    public apiImportIdGet(id: string, options?: AxiosRequestConfig) {
        return ImportsApiFp(this.configuration).apiImportIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderHistoriesApi - axios parameter creator
 * @export
 */
export const OrderHistoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderHistoryOrderIdGet: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('apiOrderHistoryOrderIdGet', 'orderId', orderId)
            const localVarPath = `/api/order-history/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderHistoriesApi - functional programming interface
 * @export
 */
export const OrderHistoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderHistoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderHistoryOrderIdGet(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderStateHistoryViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderHistoryOrderIdGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderHistoriesApi - factory interface
 * @export
 */
export const OrderHistoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderHistoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderHistoryOrderIdGet(orderId: string, options?: any): AxiosPromise<Array<OrderStateHistoryViewModel>> {
            return localVarFp.apiOrderHistoryOrderIdGet(orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderHistoriesApi - object-oriented interface
 * @export
 * @class OrderHistoriesApi
 * @extends {BaseAPI}
 */
export class OrderHistoriesApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderHistoriesApi
     */
    public apiOrderHistoryOrderIdGet(orderId: string, options?: AxiosRequestConfig) {
        return OrderHistoriesApiFp(this.configuration).apiOrderHistoryOrderIdGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderMessagesApi - axios parameter creator
 * @export
 */
export const OrderMessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} messagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersNotificationsMessagesIdResendPut: async (messagesId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messagesId' is not null or undefined
            assertParamExists('apiOrdersNotificationsMessagesIdResendPut', 'messagesId', messagesId)
            const localVarPath = `/api/orders/notifications/{messagesId}/resend`
                .replace(`{${"messagesId"}}`, encodeURIComponent(String(messagesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderMessagesApi - functional programming interface
 * @export
 */
export const OrderMessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderMessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} messagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersNotificationsMessagesIdResendPut(messagesId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersNotificationsMessagesIdResendPut(messagesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderMessagesApi - factory interface
 * @export
 */
export const OrderMessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderMessagesApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} messagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersNotificationsMessagesIdResendPut(messagesId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrdersNotificationsMessagesIdResendPut(messagesId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderMessagesApi - object-oriented interface
 * @export
 * @class OrderMessagesApi
 * @extends {BaseAPI}
 */
export class OrderMessagesApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} messagesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderMessagesApi
     */
    public apiOrdersNotificationsMessagesIdResendPut(messagesId: string, options?: AxiosRequestConfig) {
        return OrderMessagesApiFp(this.configuration).apiOrdersNotificationsMessagesIdResendPut(messagesId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderPaymentRequestsApi - axios parameter creator
 * @export
 */
export const OrderPaymentRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {ApiOrdersPaymentRequestPostRequest} [apiOrdersPaymentRequestPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersPaymentRequestPost: async (apiOrdersPaymentRequestPostRequest?: ApiOrdersPaymentRequestPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/payment-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiOrdersPaymentRequestPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderPaymentRequestsApi - functional programming interface
 * @export
 */
export const OrderPaymentRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderPaymentRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {ApiOrdersPaymentRequestPostRequest} [apiOrdersPaymentRequestPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersPaymentRequestPost(apiOrdersPaymentRequestPostRequest?: ApiOrdersPaymentRequestPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPaymentRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersPaymentRequestPost(apiOrdersPaymentRequestPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderPaymentRequestsApi - factory interface
 * @export
 */
export const OrderPaymentRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderPaymentRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {ApiOrdersPaymentRequestPostRequest} [apiOrdersPaymentRequestPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersPaymentRequestPost(apiOrdersPaymentRequestPostRequest?: ApiOrdersPaymentRequestPostRequest, options?: any): AxiosPromise<OrderPaymentRequestDto> {
            return localVarFp.apiOrdersPaymentRequestPost(apiOrdersPaymentRequestPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderPaymentRequestsApi - object-oriented interface
 * @export
 * @class OrderPaymentRequestsApi
 * @extends {BaseAPI}
 */
export class OrderPaymentRequestsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {ApiOrdersPaymentRequestPostRequest} [apiOrdersPaymentRequestPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPaymentRequestsApi
     */
    public apiOrdersPaymentRequestPost(apiOrdersPaymentRequestPostRequest?: ApiOrdersPaymentRequestPostRequest, options?: AxiosRequestConfig) {
        return OrderPaymentRequestsApiFp(this.configuration).apiOrdersPaymentRequestPost(apiOrdersPaymentRequestPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {ApiOrdersActivateOrderPutRequest} [apiOrdersActivateOrderPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersActivateOrderPut: async (apiOrdersActivateOrderPutRequest?: ApiOrdersActivateOrderPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/activate-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiOrdersActivateOrderPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ApiOrdersBypassTelkomSuspendOrderPutRequest} [apiOrdersBypassTelkomSuspendOrderPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersBypassTelkomSuspendOrderPut: async (apiOrdersBypassTelkomSuspendOrderPutRequest?: ApiOrdersBypassTelkomSuspendOrderPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/bypass-telkom-suspend-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiOrdersBypassTelkomSuspendOrderPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [filter] 
         * @param {Array<string>} [orderIds] 
         * @param {QueueStatus} [queueStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersGet: async (pageNumber: number, pageSize: number, filter?: string, orderIds?: Array<string>, queueStatus?: QueueStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiOrdersGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiOrdersGet', 'pageSize', pageSize)
            const localVarPath = `/api/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (orderIds) {
                localVarQueryParameter['orderIds'] = orderIds;
            }

            if (queueStatus !== undefined) {
                localVarQueryParameter['queueStatus'] = queueStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrdersIdGet', 'id', id)
            const localVarPath = `/api/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MessageType} [messageType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersIdMessagesGet: async (id: string, messageType?: MessageType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrdersIdMessagesGet', 'id', id)
            const localVarPath = `/api/orders/{id}/messages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (messageType !== undefined) {
                localVarQueryParameter['messageType'] = messageType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersInitiateCardChangePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/initiate-card-change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ApiOrdersMigrateOrderPutRequest} [apiOrdersMigrateOrderPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersMigrateOrderPut: async (apiOrdersMigrateOrderPutRequest?: ApiOrdersMigrateOrderPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/migrate-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiOrdersMigrateOrderPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} orderId 
         * @param {ApiOrdersOrderIdAddNewCardPostRequest} [apiOrdersOrderIdAddNewCardPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersOrderIdAddNewCardPost: async (orderId: string, apiOrdersOrderIdAddNewCardPostRequest?: ApiOrdersOrderIdAddNewCardPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('apiOrdersOrderIdAddNewCardPost', 'orderId', orderId)
            const localVarPath = `/api/orders/{orderId}/add-new-card`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiOrdersOrderIdAddNewCardPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {number} [billingDay] 
         * @param {Array<string>} [orderIds] 
         * @param {PaymentMethodStatusEnum} [paymentMethodStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersPaymentsdueGet: async (pageNo: number, pageSize: number, billingDay?: number, orderIds?: Array<string>, paymentMethodStatus?: PaymentMethodStatusEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNo' is not null or undefined
            assertParamExists('apiOrdersPaymentsdueGet', 'pageNo', pageNo)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiOrdersPaymentsdueGet', 'pageSize', pageSize)
            const localVarPath = `/api/orders/paymentsdue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (billingDay !== undefined) {
                localVarQueryParameter['billingDay'] = billingDay;
            }

            if (orderIds) {
                localVarQueryParameter['orderIds'] = orderIds;
            }

            if (paymentMethodStatus !== undefined) {
                localVarQueryParameter['paymentMethodStatus'] = paymentMethodStatus;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiOrdersProcessPaymentsPostRequest} [apiOrdersProcessPaymentsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersProcessPaymentsPost: async (apiOrdersProcessPaymentsPostRequest?: ApiOrdersProcessPaymentsPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/process-payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiOrdersProcessPaymentsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} msisdn 
         * @param {ApiOrdersProductsMsisdnCancelDeleteRequest} [apiOrdersProductsMsisdnCancelDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersProductsMsisdnCancelDelete: async (msisdn: string, apiOrdersProductsMsisdnCancelDeleteRequest?: ApiOrdersProductsMsisdnCancelDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'msisdn' is not null or undefined
            assertParamExists('apiOrdersProductsMsisdnCancelDelete', 'msisdn', msisdn)
            const localVarPath = `/api/orders/products/{msisdn}/cancel`
                .replace(`{${"msisdn"}}`, encodeURIComponent(String(msisdn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiOrdersProductsMsisdnCancelDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} msisdn 
         * @param {ApiOrdersProductsMsisdnTopupPutRequest} [apiOrdersProductsMsisdnTopupPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersProductsMsisdnMigrationPut: async (msisdn: string, apiOrdersProductsMsisdnTopupPutRequest?: ApiOrdersProductsMsisdnTopupPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'msisdn' is not null or undefined
            assertParamExists('apiOrdersProductsMsisdnMigrationPut', 'msisdn', msisdn)
            const localVarPath = `/api/orders/products/{msisdn}/migration`
                .replace(`{${"msisdn"}}`, encodeURIComponent(String(msisdn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiOrdersProductsMsisdnTopupPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} msisdn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersProductsMsisdnResumeServicePut: async (msisdn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'msisdn' is not null or undefined
            assertParamExists('apiOrdersProductsMsisdnResumeServicePut', 'msisdn', msisdn)
            const localVarPath = `/api/orders/products/{msisdn}/resume-service`
                .replace(`{${"msisdn"}}`, encodeURIComponent(String(msisdn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} msisdn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersProductsMsisdnSuspendServiceDelete: async (msisdn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'msisdn' is not null or undefined
            assertParamExists('apiOrdersProductsMsisdnSuspendServiceDelete', 'msisdn', msisdn)
            const localVarPath = `/api/orders/products/{msisdn}/suspend-service`
                .replace(`{${"msisdn"}}`, encodeURIComponent(String(msisdn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} msisdn 
         * @param {ApiOrdersProductsMsisdnTopupPutRequest} [apiOrdersProductsMsisdnTopupPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersProductsMsisdnTopupPut: async (msisdn: string, apiOrdersProductsMsisdnTopupPutRequest?: ApiOrdersProductsMsisdnTopupPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'msisdn' is not null or undefined
            assertParamExists('apiOrdersProductsMsisdnTopupPut', 'msisdn', msisdn)
            const localVarPath = `/api/orders/products/{msisdn}/topup`
                .replace(`{${"msisdn"}}`, encodeURIComponent(String(msisdn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiOrdersProductsMsisdnTopupPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ApiOrdersGetRequest} [apiOrdersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersPut: async (apiOrdersGetRequest?: ApiOrdersGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiOrdersGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersQueuesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/queues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {QueueStatus} queueStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersQueuesQueueStatusGet: async (queueStatus: QueueStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueStatus' is not null or undefined
            assertParamExists('apiOrdersQueuesQueueStatusGet', 'queueStatus', queueStatus)
            const localVarPath = `/api/orders/queues/{queueStatus}`
                .replace(`{${"queueStatus"}}`, encodeURIComponent(String(queueStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ApiOrdersReactivateOrderPutRequest} [apiOrdersReactivateOrderPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersReactivateOrderPut: async (apiOrdersReactivateOrderPutRequest?: ApiOrdersReactivateOrderPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/reactivate-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiOrdersReactivateOrderPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {ApiOrdersActivateOrderPutRequest} [apiOrdersActivateOrderPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersActivateOrderPut(apiOrdersActivateOrderPutRequest?: ApiOrdersActivateOrderPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersActivateOrderPut(apiOrdersActivateOrderPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ApiOrdersBypassTelkomSuspendOrderPutRequest} [apiOrdersBypassTelkomSuspendOrderPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersBypassTelkomSuspendOrderPut(apiOrdersBypassTelkomSuspendOrderPutRequest?: ApiOrdersBypassTelkomSuspendOrderPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersBypassTelkomSuspendOrderPut(apiOrdersBypassTelkomSuspendOrderPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [filter] 
         * @param {Array<string>} [orderIds] 
         * @param {QueueStatus} [queueStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersGet(pageNumber: number, pageSize: number, filter?: string, orderIds?: Array<string>, queueStatus?: QueueStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfOrdersViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersGet(pageNumber, pageSize, filter, orderIds, queueStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MessageType} [messageType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersIdMessagesGet(id: string, messageType?: MessageType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderMessagesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersIdMessagesGet(id, messageType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersInitiateCardChangePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersInitiateCardChangePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ApiOrdersMigrateOrderPutRequest} [apiOrdersMigrateOrderPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersMigrateOrderPut(apiOrdersMigrateOrderPutRequest?: ApiOrdersMigrateOrderPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersMigrateOrderPut(apiOrdersMigrateOrderPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} orderId 
         * @param {ApiOrdersOrderIdAddNewCardPostRequest} [apiOrdersOrderIdAddNewCardPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersOrderIdAddNewCardPost(orderId: string, apiOrdersOrderIdAddNewCardPostRequest?: ApiOrdersOrderIdAddNewCardPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersOrderIdAddNewCardPost(orderId, apiOrdersOrderIdAddNewCardPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {number} [billingDay] 
         * @param {Array<string>} [orderIds] 
         * @param {PaymentMethodStatusEnum} [paymentMethodStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersPaymentsdueGet(pageNo: number, pageSize: number, billingDay?: number, orderIds?: Array<string>, paymentMethodStatus?: PaymentMethodStatusEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfPaymentDueOrdersViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersPaymentsdueGet(pageNo, pageSize, billingDay, orderIds, paymentMethodStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiOrdersProcessPaymentsPostRequest} [apiOrdersProcessPaymentsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersProcessPaymentsPost(apiOrdersProcessPaymentsPostRequest?: ApiOrdersProcessPaymentsPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersProcessPaymentsPost(apiOrdersProcessPaymentsPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} msisdn 
         * @param {ApiOrdersProductsMsisdnCancelDeleteRequest} [apiOrdersProductsMsisdnCancelDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersProductsMsisdnCancelDelete(msisdn: string, apiOrdersProductsMsisdnCancelDeleteRequest?: ApiOrdersProductsMsisdnCancelDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersProductsMsisdnCancelDelete(msisdn, apiOrdersProductsMsisdnCancelDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} msisdn 
         * @param {ApiOrdersProductsMsisdnTopupPutRequest} [apiOrdersProductsMsisdnTopupPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersProductsMsisdnMigrationPut(msisdn: string, apiOrdersProductsMsisdnTopupPutRequest?: ApiOrdersProductsMsisdnTopupPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderProductsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersProductsMsisdnMigrationPut(msisdn, apiOrdersProductsMsisdnTopupPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} msisdn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersProductsMsisdnResumeServicePut(msisdn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersProductsMsisdnResumeServicePut(msisdn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} msisdn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersProductsMsisdnSuspendServiceDelete(msisdn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersProductsMsisdnSuspendServiceDelete(msisdn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} msisdn 
         * @param {ApiOrdersProductsMsisdnTopupPutRequest} [apiOrdersProductsMsisdnTopupPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersProductsMsisdnTopupPut(msisdn: string, apiOrdersProductsMsisdnTopupPutRequest?: ApiOrdersProductsMsisdnTopupPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderProductsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersProductsMsisdnTopupPut(msisdn, apiOrdersProductsMsisdnTopupPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ApiOrdersGetRequest} [apiOrdersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersPut(apiOrdersGetRequest?: ApiOrdersGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersPut(apiOrdersGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersQueuesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderProductGroupedQueuesViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersQueuesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {QueueStatus} queueStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersQueuesQueueStatusGet(queueStatus: QueueStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersQueuesQueueStatusGet(queueStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ApiOrdersReactivateOrderPutRequest} [apiOrdersReactivateOrderPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersReactivateOrderPut(apiOrdersReactivateOrderPutRequest?: ApiOrdersReactivateOrderPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersReactivateOrderPut(apiOrdersReactivateOrderPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {ApiOrdersActivateOrderPutRequest} [apiOrdersActivateOrderPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersActivateOrderPut(apiOrdersActivateOrderPutRequest?: ApiOrdersActivateOrderPutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrdersActivateOrderPut(apiOrdersActivateOrderPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ApiOrdersBypassTelkomSuspendOrderPutRequest} [apiOrdersBypassTelkomSuspendOrderPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersBypassTelkomSuspendOrderPut(apiOrdersBypassTelkomSuspendOrderPutRequest?: ApiOrdersBypassTelkomSuspendOrderPutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrdersBypassTelkomSuspendOrderPut(apiOrdersBypassTelkomSuspendOrderPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [filter] 
         * @param {Array<string>} [orderIds] 
         * @param {QueueStatus} [queueStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersGet(pageNumber: number, pageSize: number, filter?: string, orderIds?: Array<string>, queueStatus?: QueueStatus, options?: any): AxiosPromise<PagedResultOfOrdersViewModel> {
            return localVarFp.apiOrdersGet(pageNumber, pageSize, filter, orderIds, queueStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersIdGet(id: string, options?: any): AxiosPromise<OrdersViewModel> {
            return localVarFp.apiOrdersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MessageType} [messageType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersIdMessagesGet(id: string, messageType?: MessageType, options?: any): AxiosPromise<Array<OrderMessagesDto>> {
            return localVarFp.apiOrdersIdMessagesGet(id, messageType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersInitiateCardChangePost(options?: any): AxiosPromise<JsonResponseOfString> {
            return localVarFp.apiOrdersInitiateCardChangePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ApiOrdersMigrateOrderPutRequest} [apiOrdersMigrateOrderPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersMigrateOrderPut(apiOrdersMigrateOrderPutRequest?: ApiOrdersMigrateOrderPutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrdersMigrateOrderPut(apiOrdersMigrateOrderPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} orderId 
         * @param {ApiOrdersOrderIdAddNewCardPostRequest} [apiOrdersOrderIdAddNewCardPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersOrderIdAddNewCardPost(orderId: string, apiOrdersOrderIdAddNewCardPostRequest?: ApiOrdersOrderIdAddNewCardPostRequest, options?: any): AxiosPromise<JsonResponseOfString> {
            return localVarFp.apiOrdersOrderIdAddNewCardPost(orderId, apiOrdersOrderIdAddNewCardPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {number} [billingDay] 
         * @param {Array<string>} [orderIds] 
         * @param {PaymentMethodStatusEnum} [paymentMethodStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersPaymentsdueGet(pageNo: number, pageSize: number, billingDay?: number, orderIds?: Array<string>, paymentMethodStatus?: PaymentMethodStatusEnum, options?: any): AxiosPromise<PagedResultOfPaymentDueOrdersViewModel> {
            return localVarFp.apiOrdersPaymentsdueGet(pageNo, pageSize, billingDay, orderIds, paymentMethodStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiOrdersProcessPaymentsPostRequest} [apiOrdersProcessPaymentsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersProcessPaymentsPost(apiOrdersProcessPaymentsPostRequest?: ApiOrdersProcessPaymentsPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrdersProcessPaymentsPost(apiOrdersProcessPaymentsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} msisdn 
         * @param {ApiOrdersProductsMsisdnCancelDeleteRequest} [apiOrdersProductsMsisdnCancelDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersProductsMsisdnCancelDelete(msisdn: string, apiOrdersProductsMsisdnCancelDeleteRequest?: ApiOrdersProductsMsisdnCancelDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrdersProductsMsisdnCancelDelete(msisdn, apiOrdersProductsMsisdnCancelDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} msisdn 
         * @param {ApiOrdersProductsMsisdnTopupPutRequest} [apiOrdersProductsMsisdnTopupPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersProductsMsisdnMigrationPut(msisdn: string, apiOrdersProductsMsisdnTopupPutRequest?: ApiOrdersProductsMsisdnTopupPutRequest, options?: any): AxiosPromise<OrderProductsViewModel> {
            return localVarFp.apiOrdersProductsMsisdnMigrationPut(msisdn, apiOrdersProductsMsisdnTopupPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} msisdn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersProductsMsisdnResumeServicePut(msisdn: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrdersProductsMsisdnResumeServicePut(msisdn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} msisdn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersProductsMsisdnSuspendServiceDelete(msisdn: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrdersProductsMsisdnSuspendServiceDelete(msisdn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} msisdn 
         * @param {ApiOrdersProductsMsisdnTopupPutRequest} [apiOrdersProductsMsisdnTopupPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersProductsMsisdnTopupPut(msisdn: string, apiOrdersProductsMsisdnTopupPutRequest?: ApiOrdersProductsMsisdnTopupPutRequest, options?: any): AxiosPromise<OrderProductsViewModel> {
            return localVarFp.apiOrdersProductsMsisdnTopupPut(msisdn, apiOrdersProductsMsisdnTopupPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ApiOrdersGetRequest} [apiOrdersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersPut(apiOrdersGetRequest?: ApiOrdersGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrdersPut(apiOrdersGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersQueuesGet(options?: any): AxiosPromise<OrderProductGroupedQueuesViewModel> {
            return localVarFp.apiOrdersQueuesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {QueueStatus} queueStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersQueuesQueueStatusGet(queueStatus: QueueStatus, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiOrdersQueuesQueueStatusGet(queueStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ApiOrdersReactivateOrderPutRequest} [apiOrdersReactivateOrderPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersReactivateOrderPut(apiOrdersReactivateOrderPutRequest?: ApiOrdersReactivateOrderPutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrdersReactivateOrderPut(apiOrdersReactivateOrderPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {ApiOrdersActivateOrderPutRequest} [apiOrdersActivateOrderPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersActivateOrderPut(apiOrdersActivateOrderPutRequest?: ApiOrdersActivateOrderPutRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersActivateOrderPut(apiOrdersActivateOrderPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ApiOrdersBypassTelkomSuspendOrderPutRequest} [apiOrdersBypassTelkomSuspendOrderPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersBypassTelkomSuspendOrderPut(apiOrdersBypassTelkomSuspendOrderPutRequest?: ApiOrdersBypassTelkomSuspendOrderPutRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersBypassTelkomSuspendOrderPut(apiOrdersBypassTelkomSuspendOrderPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [filter] 
     * @param {Array<string>} [orderIds] 
     * @param {QueueStatus} [queueStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersGet(pageNumber: number, pageSize: number, filter?: string, orderIds?: Array<string>, queueStatus?: QueueStatus, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersGet(pageNumber, pageSize, filter, orderIds, queueStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersIdGet(id: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MessageType} [messageType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersIdMessagesGet(id: string, messageType?: MessageType, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersIdMessagesGet(id, messageType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersInitiateCardChangePost(options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersInitiateCardChangePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ApiOrdersMigrateOrderPutRequest} [apiOrdersMigrateOrderPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersMigrateOrderPut(apiOrdersMigrateOrderPutRequest?: ApiOrdersMigrateOrderPutRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersMigrateOrderPut(apiOrdersMigrateOrderPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} orderId 
     * @param {ApiOrdersOrderIdAddNewCardPostRequest} [apiOrdersOrderIdAddNewCardPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersOrderIdAddNewCardPost(orderId: string, apiOrdersOrderIdAddNewCardPostRequest?: ApiOrdersOrderIdAddNewCardPostRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersOrderIdAddNewCardPost(orderId, apiOrdersOrderIdAddNewCardPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} pageNo 
     * @param {number} pageSize 
     * @param {number} [billingDay] 
     * @param {Array<string>} [orderIds] 
     * @param {PaymentMethodStatusEnum} [paymentMethodStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersPaymentsdueGet(pageNo: number, pageSize: number, billingDay?: number, orderIds?: Array<string>, paymentMethodStatus?: PaymentMethodStatusEnum, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersPaymentsdueGet(pageNo, pageSize, billingDay, orderIds, paymentMethodStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiOrdersProcessPaymentsPostRequest} [apiOrdersProcessPaymentsPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersProcessPaymentsPost(apiOrdersProcessPaymentsPostRequest?: ApiOrdersProcessPaymentsPostRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersProcessPaymentsPost(apiOrdersProcessPaymentsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} msisdn 
     * @param {ApiOrdersProductsMsisdnCancelDeleteRequest} [apiOrdersProductsMsisdnCancelDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersProductsMsisdnCancelDelete(msisdn: string, apiOrdersProductsMsisdnCancelDeleteRequest?: ApiOrdersProductsMsisdnCancelDeleteRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersProductsMsisdnCancelDelete(msisdn, apiOrdersProductsMsisdnCancelDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} msisdn 
     * @param {ApiOrdersProductsMsisdnTopupPutRequest} [apiOrdersProductsMsisdnTopupPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersProductsMsisdnMigrationPut(msisdn: string, apiOrdersProductsMsisdnTopupPutRequest?: ApiOrdersProductsMsisdnTopupPutRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersProductsMsisdnMigrationPut(msisdn, apiOrdersProductsMsisdnTopupPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} msisdn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersProductsMsisdnResumeServicePut(msisdn: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersProductsMsisdnResumeServicePut(msisdn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} msisdn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersProductsMsisdnSuspendServiceDelete(msisdn: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersProductsMsisdnSuspendServiceDelete(msisdn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} msisdn 
     * @param {ApiOrdersProductsMsisdnTopupPutRequest} [apiOrdersProductsMsisdnTopupPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersProductsMsisdnTopupPut(msisdn: string, apiOrdersProductsMsisdnTopupPutRequest?: ApiOrdersProductsMsisdnTopupPutRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersProductsMsisdnTopupPut(msisdn, apiOrdersProductsMsisdnTopupPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ApiOrdersGetRequest} [apiOrdersGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersPut(apiOrdersGetRequest?: ApiOrdersGetRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersPut(apiOrdersGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersQueuesGet(options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersQueuesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {QueueStatus} queueStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersQueuesQueueStatusGet(queueStatus: QueueStatus, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersQueuesQueueStatusGet(queueStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ApiOrdersReactivateOrderPutRequest} [apiOrdersReactivateOrderPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersReactivateOrderPut(apiOrdersReactivateOrderPutRequest?: ApiOrdersReactivateOrderPutRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersReactivateOrderPut(apiOrdersReactivateOrderPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} ordersId 
         * @param {PaymentPeriod} [period] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersOrdersIdPaymentGet: async (pageNumber: number, pageSize: number, ordersId: string, period?: PaymentPeriod, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiOrdersOrdersIdPaymentGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiOrdersOrdersIdPaymentGet', 'pageSize', pageSize)
            // verify required parameter 'ordersId' is not null or undefined
            assertParamExists('apiOrdersOrdersIdPaymentGet', 'ordersId', ordersId)
            const localVarPath = `/api/orders/{ordersId}/payment`
                .replace(`{${"ordersId"}}`, encodeURIComponent(String(ordersId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} ordersId 
         * @param {PaymentPeriod} [period] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersOrdersIdPaymentsExportGet: async (ordersId: string, period?: PaymentPeriod, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ordersId' is not null or undefined
            assertParamExists('apiOrdersOrdersIdPaymentsExportGet', 'ordersId', ordersId)
            const localVarPath = `/api/orders/{ordersId}/payments/export`
                .replace(`{${"ordersId"}}`, encodeURIComponent(String(ordersId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ApiPaymentsManualPaymentImportPutRequest} [apiPaymentsManualPaymentImportPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentsManualPaymentImportPut: async (apiPaymentsManualPaymentImportPutRequest?: ApiPaymentsManualPaymentImportPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payments/manual-payment-import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiPaymentsManualPaymentImportPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} ordersId 
         * @param {PaymentPeriod} [period] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersOrdersIdPaymentGet(pageNumber: number, pageSize: number, ordersId: string, period?: PaymentPeriod, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfOrderPaymentViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersOrdersIdPaymentGet(pageNumber, pageSize, ordersId, period, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} ordersId 
         * @param {PaymentPeriod} [period] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersOrdersIdPaymentsExportGet(ordersId: string, period?: PaymentPeriod, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersOrdersIdPaymentsExportGet(ordersId, period, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ApiPaymentsManualPaymentImportPutRequest} [apiPaymentsManualPaymentImportPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentsManualPaymentImportPut(apiPaymentsManualPaymentImportPutRequest?: ApiPaymentsManualPaymentImportPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfListOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentsManualPaymentImportPut(apiPaymentsManualPaymentImportPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} ordersId 
         * @param {PaymentPeriod} [period] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersOrdersIdPaymentGet(pageNumber: number, pageSize: number, ordersId: string, period?: PaymentPeriod, options?: any): AxiosPromise<PagedResultOfOrderPaymentViewModel> {
            return localVarFp.apiOrdersOrdersIdPaymentGet(pageNumber, pageSize, ordersId, period, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} ordersId 
         * @param {PaymentPeriod} [period] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersOrdersIdPaymentsExportGet(ordersId: string, period?: PaymentPeriod, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.apiOrdersOrdersIdPaymentsExportGet(ordersId, period, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ApiPaymentsManualPaymentImportPutRequest} [apiPaymentsManualPaymentImportPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentsManualPaymentImportPut(apiPaymentsManualPaymentImportPutRequest?: ApiPaymentsManualPaymentImportPutRequest, options?: any): AxiosPromise<JsonResponseOfListOfString> {
            return localVarFp.apiPaymentsManualPaymentImportPut(apiPaymentsManualPaymentImportPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} ordersId 
     * @param {PaymentPeriod} [period] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiOrdersOrdersIdPaymentGet(pageNumber: number, pageSize: number, ordersId: string, period?: PaymentPeriod, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiOrdersOrdersIdPaymentGet(pageNumber, pageSize, ordersId, period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} ordersId 
     * @param {PaymentPeriod} [period] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiOrdersOrdersIdPaymentsExportGet(ordersId: string, period?: PaymentPeriod, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiOrdersOrdersIdPaymentsExportGet(ordersId, period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ApiPaymentsManualPaymentImportPutRequest} [apiPaymentsManualPaymentImportPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public apiPaymentsManualPaymentImportPut(apiPaymentsManualPaymentImportPutRequest?: ApiPaymentsManualPaymentImportPutRequest, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).apiPaymentsManualPaymentImportPut(apiPaymentsManualPaymentImportPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PowerBiReportClaimsApi - axios parameter creator
 * @export
 */
export const PowerBiReportClaimsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportClaimGet: async (pageNo: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNo' is not null or undefined
            assertParamExists('apiPowerBiReportClaimGet', 'pageNo', pageNo)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiPowerBiReportClaimGet', 'pageSize', pageSize)
            const localVarPath = `/api/power-bi-report-claim`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportClaimIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPowerBiReportClaimIdDelete', 'id', id)
            const localVarPath = `/api/power-bi-report-claim/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportClaimIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPowerBiReportClaimIdGet', 'id', id)
            const localVarPath = `/api/power-bi-report-claim/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiPowerBiReportClaimIdDeleteRequest} [apiPowerBiReportClaimIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportClaimIdPut: async (id: string, apiPowerBiReportClaimIdDeleteRequest?: ApiPowerBiReportClaimIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPowerBiReportClaimIdPut', 'id', id)
            const localVarPath = `/api/power-bi-report-claim/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiPowerBiReportClaimIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ApiPowerBiReportClaimGetRequest} [apiPowerBiReportClaimGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportClaimPost: async (apiPowerBiReportClaimGetRequest?: ApiPowerBiReportClaimGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/power-bi-report-claim`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiPowerBiReportClaimGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PowerBiReportClaimsApi - functional programming interface
 * @export
 */
export const PowerBiReportClaimsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PowerBiReportClaimsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPowerBiReportClaimGet(pageNo: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfPowerBiReportClaimDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPowerBiReportClaimGet(pageNo, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPowerBiReportClaimIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPowerBiReportClaimIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPowerBiReportClaimIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PowerBiReportClaimDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPowerBiReportClaimIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiPowerBiReportClaimIdDeleteRequest} [apiPowerBiReportClaimIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPowerBiReportClaimIdPut(id: string, apiPowerBiReportClaimIdDeleteRequest?: ApiPowerBiReportClaimIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPowerBiReportClaimIdPut(id, apiPowerBiReportClaimIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ApiPowerBiReportClaimGetRequest} [apiPowerBiReportClaimGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPowerBiReportClaimPost(apiPowerBiReportClaimGetRequest?: ApiPowerBiReportClaimGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPowerBiReportClaimPost(apiPowerBiReportClaimGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PowerBiReportClaimsApi - factory interface
 * @export
 */
export const PowerBiReportClaimsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PowerBiReportClaimsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportClaimGet(pageNo: number, pageSize: number, options?: any): AxiosPromise<PagedResultOfPowerBiReportClaimDto> {
            return localVarFp.apiPowerBiReportClaimGet(pageNo, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportClaimIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPowerBiReportClaimIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportClaimIdGet(id: string, options?: any): AxiosPromise<PowerBiReportClaimDto> {
            return localVarFp.apiPowerBiReportClaimIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiPowerBiReportClaimIdDeleteRequest} [apiPowerBiReportClaimIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportClaimIdPut(id: string, apiPowerBiReportClaimIdDeleteRequest?: ApiPowerBiReportClaimIdDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiPowerBiReportClaimIdPut(id, apiPowerBiReportClaimIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ApiPowerBiReportClaimGetRequest} [apiPowerBiReportClaimGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportClaimPost(apiPowerBiReportClaimGetRequest?: ApiPowerBiReportClaimGetRequest, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiPowerBiReportClaimPost(apiPowerBiReportClaimGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PowerBiReportClaimsApi - object-oriented interface
 * @export
 * @class PowerBiReportClaimsApi
 * @extends {BaseAPI}
 */
export class PowerBiReportClaimsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} pageNo 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiReportClaimsApi
     */
    public apiPowerBiReportClaimGet(pageNo: number, pageSize: number, options?: AxiosRequestConfig) {
        return PowerBiReportClaimsApiFp(this.configuration).apiPowerBiReportClaimGet(pageNo, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiReportClaimsApi
     */
    public apiPowerBiReportClaimIdDelete(id: string, options?: AxiosRequestConfig) {
        return PowerBiReportClaimsApiFp(this.configuration).apiPowerBiReportClaimIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiReportClaimsApi
     */
    public apiPowerBiReportClaimIdGet(id: string, options?: AxiosRequestConfig) {
        return PowerBiReportClaimsApiFp(this.configuration).apiPowerBiReportClaimIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {ApiPowerBiReportClaimIdDeleteRequest} [apiPowerBiReportClaimIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiReportClaimsApi
     */
    public apiPowerBiReportClaimIdPut(id: string, apiPowerBiReportClaimIdDeleteRequest?: ApiPowerBiReportClaimIdDeleteRequest, options?: AxiosRequestConfig) {
        return PowerBiReportClaimsApiFp(this.configuration).apiPowerBiReportClaimIdPut(id, apiPowerBiReportClaimIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ApiPowerBiReportClaimGetRequest} [apiPowerBiReportClaimGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiReportClaimsApi
     */
    public apiPowerBiReportClaimPost(apiPowerBiReportClaimGetRequest?: ApiPowerBiReportClaimGetRequest, options?: AxiosRequestConfig) {
        return PowerBiReportClaimsApiFp(this.configuration).apiPowerBiReportClaimPost(apiPowerBiReportClaimGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PowerBiReportsApi - axios parameter creator
 * @export
 */
export const PowerBiReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportGet: async (pageNo: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNo' is not null or undefined
            assertParamExists('apiPowerBiReportGet', 'pageNo', pageNo)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiPowerBiReportGet', 'pageSize', pageSize)
            const localVarPath = `/api/power-bi-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPowerBiReportIdDelete', 'id', id)
            const localVarPath = `/api/power-bi-report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPowerBiReportIdGet', 'id', id)
            const localVarPath = `/api/power-bi-report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {ApiPowerBiReportIdDeleteRequest} [apiPowerBiReportIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportIdPut: async (id: number, apiPowerBiReportIdDeleteRequest?: ApiPowerBiReportIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPowerBiReportIdPut', 'id', id)
            const localVarPath = `/api/power-bi-report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiPowerBiReportIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ApiPowerBiReportGetRequest} [apiPowerBiReportGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportPost: async (apiPowerBiReportGetRequest?: ApiPowerBiReportGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/power-bi-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiPowerBiReportGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PowerBiReportsApi - functional programming interface
 * @export
 */
export const PowerBiReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PowerBiReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPowerBiReportGet(pageNo: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfPowerBiReportsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPowerBiReportGet(pageNo, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPowerBiReportIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPowerBiReportIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPowerBiReportIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PowerBiReportsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPowerBiReportIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {ApiPowerBiReportIdDeleteRequest} [apiPowerBiReportIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPowerBiReportIdPut(id: number, apiPowerBiReportIdDeleteRequest?: ApiPowerBiReportIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPowerBiReportIdPut(id, apiPowerBiReportIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ApiPowerBiReportGetRequest} [apiPowerBiReportGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPowerBiReportPost(apiPowerBiReportGetRequest?: ApiPowerBiReportGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfInt32>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPowerBiReportPost(apiPowerBiReportGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PowerBiReportsApi - factory interface
 * @export
 */
export const PowerBiReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PowerBiReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNo 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportGet(pageNo: number, pageSize: number, options?: any): AxiosPromise<PagedResultOfPowerBiReportsDto> {
            return localVarFp.apiPowerBiReportGet(pageNo, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiPowerBiReportIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportIdGet(id: number, options?: any): AxiosPromise<PowerBiReportsDto> {
            return localVarFp.apiPowerBiReportIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {ApiPowerBiReportIdDeleteRequest} [apiPowerBiReportIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportIdPut(id: number, apiPowerBiReportIdDeleteRequest?: ApiPowerBiReportIdDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiPowerBiReportIdPut(id, apiPowerBiReportIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ApiPowerBiReportGetRequest} [apiPowerBiReportGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPowerBiReportPost(apiPowerBiReportGetRequest?: ApiPowerBiReportGetRequest, options?: any): AxiosPromise<JsonResponseOfInt32> {
            return localVarFp.apiPowerBiReportPost(apiPowerBiReportGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PowerBiReportsApi - object-oriented interface
 * @export
 * @class PowerBiReportsApi
 * @extends {BaseAPI}
 */
export class PowerBiReportsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} pageNo 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiReportsApi
     */
    public apiPowerBiReportGet(pageNo: number, pageSize: number, options?: AxiosRequestConfig) {
        return PowerBiReportsApiFp(this.configuration).apiPowerBiReportGet(pageNo, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiReportsApi
     */
    public apiPowerBiReportIdDelete(id: number, options?: AxiosRequestConfig) {
        return PowerBiReportsApiFp(this.configuration).apiPowerBiReportIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiReportsApi
     */
    public apiPowerBiReportIdGet(id: number, options?: AxiosRequestConfig) {
        return PowerBiReportsApiFp(this.configuration).apiPowerBiReportIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {ApiPowerBiReportIdDeleteRequest} [apiPowerBiReportIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiReportsApi
     */
    public apiPowerBiReportIdPut(id: number, apiPowerBiReportIdDeleteRequest?: ApiPowerBiReportIdDeleteRequest, options?: AxiosRequestConfig) {
        return PowerBiReportsApiFp(this.configuration).apiPowerBiReportIdPut(id, apiPowerBiReportIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ApiPowerBiReportGetRequest} [apiPowerBiReportGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PowerBiReportsApi
     */
    public apiPowerBiReportPost(apiPowerBiReportGetRequest?: ApiPowerBiReportGetRequest, options?: AxiosRequestConfig) {
        return PowerBiReportsApiFp(this.configuration).apiPowerBiReportPost(apiPowerBiReportGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsGet: async (pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiProductsGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiProductsGet', 'pageSize', pageSize)
            const localVarPath = `/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsIdGet', 'id', id)
            const localVarPath = `/api/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApiProductsIdGetRequest} [apiProductsIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdPut: async (id: number, apiProductsIdGetRequest?: ApiProductsIdGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsIdPut', 'id', id)
            const localVarPath = `/api/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiProductsIdGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} [currentProductId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsMigrationListGet: async (currentProductId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/migration/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currentProductId !== undefined) {
                localVarQueryParameter['currentProductId'] = currentProductId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiProductsGetRequest} [apiProductsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsPost: async (apiProductsGetRequest?: ApiProductsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiProductsGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsTopupListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/topup/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfProductsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsGet(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApiProductsIdGetRequest} [apiProductsIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsIdPut(id: number, apiProductsIdGetRequest?: ApiProductsIdGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsIdPut(id, apiProductsIdGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} [currentProductId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsMigrationListGet(currentProductId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductsListViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsMigrationListGet(currentProductId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApiProductsGetRequest} [apiProductsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsPost(apiProductsGetRequest?: ApiProductsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsPost(apiProductsGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsTopupListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductsListViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsTopupListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsGet(pageNumber: number, pageSize: number, options?: any): AxiosPromise<PagedResultOfProductsViewModel> {
            return localVarFp.apiProductsGet(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdGet(id: number, options?: any): AxiosPromise<ProductsViewModel> {
            return localVarFp.apiProductsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApiProductsIdGetRequest} [apiProductsIdGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdPut(id: number, apiProductsIdGetRequest?: ApiProductsIdGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsIdPut(id, apiProductsIdGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} [currentProductId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsMigrationListGet(currentProductId?: number, options?: any): AxiosPromise<Array<ProductsListViewModel>> {
            return localVarFp.apiProductsMigrationListGet(currentProductId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiProductsGetRequest} [apiProductsGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsPost(apiProductsGetRequest?: ApiProductsGetRequest, options?: any): AxiosPromise<number> {
            return localVarFp.apiProductsPost(apiProductsGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsTopupListGet(options?: any): AxiosPromise<Array<ProductsListViewModel>> {
            return localVarFp.apiProductsTopupListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsGet(pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsGet(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsIdGet(id: number, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApiProductsIdGetRequest} [apiProductsIdGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsIdPut(id: number, apiProductsIdGetRequest?: ApiProductsIdGetRequest, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsIdPut(id, apiProductsIdGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} [currentProductId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsMigrationListGet(currentProductId?: number, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsMigrationListGet(currentProductId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiProductsGetRequest} [apiProductsGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsPost(apiProductsGetRequest?: ApiProductsGetRequest, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsPost(apiProductsGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsTopupListGet(options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsTopupListGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClaimsGet: async (pageNumber: number, pageSize: number, searchText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiClaimsGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiClaimsGet', 'pageSize', pageSize)
            const localVarPath = `/api/claims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRolesDelete', 'id', id)
            const localVarPath = `/api/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesGet: async (pageNumber: number, pageSize: number, searchText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiRolesGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiRolesGet', 'pageSize', pageSize)
            const localVarPath = `/api/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRolesIdGet', 'id', id)
            const localVarPath = `/api/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ApiRolesDeleteRequest1} [apiRolesDeleteRequest1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesPost: async (apiRolesDeleteRequest1?: ApiRolesDeleteRequest1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiRolesDeleteRequest1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ApiRolesDeleteRequest} [apiRolesDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesPut: async (apiRolesDeleteRequest?: ApiRolesDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiRolesDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClaimsGet(pageNumber: number, pageSize: number, searchText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfClaimListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClaimsGet(pageNumber, pageSize, searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesGet(pageNumber: number, pageSize: number, searchText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfRoleListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesGet(pageNumber, pageSize, searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ApiRolesDeleteRequest1} [apiRolesDeleteRequest1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesPost(apiRolesDeleteRequest1?: ApiRolesDeleteRequest1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesPost(apiRolesDeleteRequest1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ApiRolesDeleteRequest} [apiRolesDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesPut(apiRolesDeleteRequest?: ApiRolesDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesPut(apiRolesDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClaimsGet(pageNumber: number, pageSize: number, searchText?: string, options?: any): AxiosPromise<PagedResultOfClaimListViewModel> {
            return localVarFp.apiClaimsGet(pageNumber, pageSize, searchText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiRolesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesGet(pageNumber: number, pageSize: number, searchText?: string, options?: any): AxiosPromise<PagedResultOfRoleListViewModel> {
            return localVarFp.apiRolesGet(pageNumber, pageSize, searchText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesIdGet(id: string, options?: any): AxiosPromise<RoleViewModel> {
            return localVarFp.apiRolesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ApiRolesDeleteRequest1} [apiRolesDeleteRequest1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesPost(apiRolesDeleteRequest1?: ApiRolesDeleteRequest1, options?: any): AxiosPromise<JsonResponseOfString> {
            return localVarFp.apiRolesPost(apiRolesDeleteRequest1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ApiRolesDeleteRequest} [apiRolesDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesPut(apiRolesDeleteRequest?: ApiRolesDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiRolesPut(apiRolesDeleteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiClaimsGet(pageNumber: number, pageSize: number, searchText?: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiClaimsGet(pageNumber, pageSize, searchText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesDelete(id: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesGet(pageNumber: number, pageSize: number, searchText?: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesGet(pageNumber, pageSize, searchText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesIdGet(id: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ApiRolesDeleteRequest1} [apiRolesDeleteRequest1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesPost(apiRolesDeleteRequest1?: ApiRolesDeleteRequest1, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesPost(apiRolesDeleteRequest1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ApiRolesDeleteRequest} [apiRolesDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesPut(apiRolesDeleteRequest?: ApiRolesDeleteRequest, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesPut(apiRolesDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TelkomProductOrderServicesApi - axios parameter creator
 * @export
 */
export const TelkomProductOrderServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} productOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTelkomProductOrderServicesCheckOrderStatusProductOrderIdGet: async (productOrderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productOrderId' is not null or undefined
            assertParamExists('apiTelkomProductOrderServicesCheckOrderStatusProductOrderIdGet', 'productOrderId', productOrderId)
            const localVarPath = `/api/telkom-product-order-services/check-order-status/{productOrderId}`
                .replace(`{${"productOrderId"}}`, encodeURIComponent(String(productOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} msisdn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTelkomProductOrderServicesCheckServiceMsisdnGet: async (msisdn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'msisdn' is not null or undefined
            assertParamExists('apiTelkomProductOrderServicesCheckServiceMsisdnGet', 'msisdn', msisdn)
            const localVarPath = `/api/telkom-product-order-services/check-service/{msisdn}`
                .replace(`{${"msisdn"}}`, encodeURIComponent(String(msisdn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TelkomProductOrderServicesApi - functional programming interface
 * @export
 */
export const TelkomProductOrderServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TelkomProductOrderServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} productOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTelkomProductOrderServicesCheckOrderStatusProductOrderIdGet(productOrderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProdcutOrderStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTelkomProductOrderServicesCheckOrderStatusProductOrderIdGet(productOrderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} msisdn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTelkomProductOrderServicesCheckServiceMsisdnGet(msisdn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetServiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTelkomProductOrderServicesCheckServiceMsisdnGet(msisdn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TelkomProductOrderServicesApi - factory interface
 * @export
 */
export const TelkomProductOrderServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TelkomProductOrderServicesApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} productOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTelkomProductOrderServicesCheckOrderStatusProductOrderIdGet(productOrderId: string, options?: any): AxiosPromise<GetProdcutOrderStatusDto> {
            return localVarFp.apiTelkomProductOrderServicesCheckOrderStatusProductOrderIdGet(productOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} msisdn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTelkomProductOrderServicesCheckServiceMsisdnGet(msisdn: string, options?: any): AxiosPromise<GetServiceDto> {
            return localVarFp.apiTelkomProductOrderServicesCheckServiceMsisdnGet(msisdn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TelkomProductOrderServicesApi - object-oriented interface
 * @export
 * @class TelkomProductOrderServicesApi
 * @extends {BaseAPI}
 */
export class TelkomProductOrderServicesApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} productOrderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TelkomProductOrderServicesApi
     */
    public apiTelkomProductOrderServicesCheckOrderStatusProductOrderIdGet(productOrderId: string, options?: AxiosRequestConfig) {
        return TelkomProductOrderServicesApiFp(this.configuration).apiTelkomProductOrderServicesCheckOrderStatusProductOrderIdGet(productOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} msisdn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TelkomProductOrderServicesApi
     */
    public apiTelkomProductOrderServicesCheckServiceMsisdnGet(msisdn: string, options?: AxiosRequestConfig) {
        return TelkomProductOrderServicesApiFp(this.configuration).apiTelkomProductOrderServicesCheckServiceMsisdnGet(msisdn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchText] 
         * @param {Array<string>} [roleIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet: async (pageNumber: number, pageSize: number, searchText?: string, roleIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiUsersGet', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiUsersGet', 'pageSize', pageSize)
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (roleIds) {
                localVarQueryParameter['roleIds'] = roleIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersIdDelete', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersIdGet', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiUsersIdDeleteRequest} [apiUsersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdPut: async (id: string, apiUsersIdDeleteRequest?: ApiUsersIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersIdPut', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiUsersIdDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ApiUsersGetRequest} [apiUsersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPost: async (apiUsersGetRequest?: ApiUsersGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiUsersGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchText] 
         * @param {Array<string>} [roleIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGet(pageNumber: number, pageSize: number, searchText?: string, roleIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfUserViewModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGet(pageNumber, pageSize, searchText, roleIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserViewModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiUsersIdDeleteRequest} [apiUsersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersIdPut(id: string, apiUsersIdDeleteRequest?: ApiUsersIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersIdPut(id, apiUsersIdDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ApiUsersGetRequest} [apiUsersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersPost(apiUsersGetRequest?: ApiUsersGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersPost(apiUsersGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {string} [searchText] 
         * @param {Array<string>} [roleIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet(pageNumber: number, pageSize: number, searchText?: string, roleIds?: Array<string>, options?: any): AxiosPromise<PagedResultOfUserViewModelDto> {
            return localVarFp.apiUsersGet(pageNumber, pageSize, searchText, roleIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdGet(id: string, options?: any): AxiosPromise<UserViewModelDto> {
            return localVarFp.apiUsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ApiUsersIdDeleteRequest} [apiUsersIdDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdPut(id: string, apiUsersIdDeleteRequest?: ApiUsersIdDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersIdPut(id, apiUsersIdDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ApiUsersGetRequest} [apiUsersGetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPost(apiUsersGetRequest?: ApiUsersGetRequest, options?: any): AxiosPromise<JsonResponseOfString> {
            return localVarFp.apiUsersPost(apiUsersGetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {string} [searchText] 
     * @param {Array<string>} [roleIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersGet(pageNumber: number, pageSize: number, searchText?: string, roleIds?: Array<string>, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersGet(pageNumber, pageSize, searchText, roleIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersIdDelete(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersIdGet(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {ApiUsersIdDeleteRequest} [apiUsersIdDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersIdPut(id: string, apiUsersIdDeleteRequest?: ApiUsersIdDeleteRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersIdPut(id, apiUsersIdDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ApiUsersGetRequest} [apiUsersGetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersPost(apiUsersGetRequest?: ApiUsersGetRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersPost(apiUsersGetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


